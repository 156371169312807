import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
  selector: 'mvta-theme-editor-payment-methods',
  templateUrl: 'payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})

export class ThemeEditorPaymentMethodsConfigComponent implements OnInit {

    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    paymentmethods = [];

    paypal;
    visa;
    mastercard;
    amex;

    constructor(private sectionService: ThemeEditorSectionConfigService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }

        this.paymentmethods = this.sectionValue.settings.cards;
        if (this.paymentmethods !== undefined) {
          this.paypal = this.paymentmethods.find((a) => a.name === 'paypal');
          this.visa = this.paymentmethods.find((a) => a.name === 'visa');
          this.mastercard = this.paymentmethods.find((a) => a.name === 'mastercard');
          this.amex = this.paymentmethods.find((a) => a.name === 'amex');
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    onPayPal() {
      this.sectionValue.settings.cards.find((a) => a.name === 'paypal').show = this.paypal.show;
      this.onSectionChange();
    }
    onVISA() {
      this.sectionValue.settings.cards.find((a) => a.name === 'visa').show = this.visa.show;
      this.onSectionChange();
    }
    onMasterCard() {
      this.sectionValue.settings.cards.find((a) => a.name === 'mastercard').show = this.mastercard.show;
      this.onSectionChange();
    }
    onAmericanExpress() {
      this.sectionValue.settings.cards.find((a) => a.name === 'amex').show = this.amex.show;
      this.onSectionChange();
    }

    defaultValues() {
        return {
            type: 'payment_methods',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: {
              payment_methodsvars: {},
            },
        };
    }
}
