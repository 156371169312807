import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys',  pure: false })
export class KeysPipe implements PipeTransform {
    transform(value: any, args: any[] = null): any {
        if (!value || typeof value !== 'object') {
            return [];
        }

        return Object.keys(value);
    }
}
