import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import { EmbeddedProducts } from '../widget/embedded_products.interface';


@Injectable()
export class ThemeEditorService {

  constructor(private http: HttpClient) { }

  getLayouts(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/embeddable-layouts`);
  }

  getImages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/images`);
  }

  uploadImage(imageData: string): Observable<any> {
    const formData = new FormData();
    formData.append('image', imageData);

    return this.http.post(environment.apiUrl + '/api/v1/admin/image', formData);
  }

  publishThemeDescription(themeId: string, descriptionJson: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/theme/${themeId}/update`, descriptionJson);
  }

  getEmbeddedSettings(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/embeddable-layout/settings`);
  }

  themeStatus(theme: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/theme/${theme}/status`);
  }

  addEmbeddedProductsLayout(embedded: EmbeddedProducts, createJWT: boolean = false): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/embeddable-layout?createJWT=${createJWT.toString()}`, embedded);
  }
}
