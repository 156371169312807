import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { AccountsService } from '../../account/account.service';
import { fadeInOutTranslate } from '../../shared/elements/animation';
import { PaymentsService } from '../payments.service';

declare const OpenPay: any;

@Component({
    selector: 'mvta-payments-make',
    templateUrl: './make.component.html',
    providers: [AccountsService, PaymentsService],
    animations: [fadeInOutTranslate]
})
export class PaymentsMakeComponent implements OnInit {

    payment;
    inputMasks = {
        email: emailMask,
        phone: [
            '(', /\d/, /\d/, ')', ' ',
            /\d/, /\d/, ' ',
            /\d/, /\d/, ' ',
            /\d/, /\d/, ' ',
            /\d/, /\d/, ' '
        ], // (dd) dd dd dd dd
        amount: createNumberMask({prefix: '$ ', suffix: '', allowDecimal: true}),
        cardNumber: [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' '
        ], // dddd dddd dddd dddd
        expiration: [
            /\d/, /\d/, ' ', '/', ' ',
            /\d/, /\d/
        ], // dd / dd
        cvv: [
            /\d/, /\d/, /\d/, /\d?/
        ] // ddd d 4 digit is optional
    };
    paymentGateways;
    loadingGateways = true;
    noGateway = true;
    makingCharge = false;

    constructor(private modalService: NgbModal, private toastrService: ToastrService, private accountServices: AccountsService,
        private paymentsService: PaymentsService, private router: Router) {
        this.payment = {amount: '', cardNumber: '', cardExpiration: '', cardCvv: '', cardExpMonth: 0, cardExpYear: 0, phone: '', email: ''};
    }

    ngOnInit() {
        // Get payment information
        this.accountServices.getLogisticsSettings()
            .subscribe((response) => {
                this.paymentGateways = response.payment;
                this.setupOpenPay();
                this.loadingGateways = false;
            }, (error) => {
                console.error('Can not get payment gateways')
                this.toastrService.error('No se han configurado correctamente los procesadores pago');
                this.loadingGateways = false;
            });
    }

    onDoCharge(ev) {
        // Remove masked values
        const data = Object.assign({}, this.payment);
        data.phone = data.phone.replace(/\(/g, '').replace(/\)/, '');
        data.amount = data.amount.replace(/\$/g, '').replace(/,/g, '').replace(/ /g, '');
        data.cardNumber = data.cardNumber.replace(/ /g, '').replace(/_/g, '');
        data.cardExpMonth = Number.parseInt(data.cardExpiration.replace(/ /g, '').split('/')[0]);
        data.cardExpYear = Number.parseInt(data.cardExpiration.replace(/ /g, '').split('/')[1]);
        data.cardCvv = data.cardCvv.replace(/_/g, '');

        // Validations
        if (Number.parseInt(data.amount) < 0 ||  data.cardNumber.length !== 16 ||
            data.cardExpMonth < 1 || data.cardExpMonth > 12 || data.cardCvv.length < 3) {
            this.toastrService.warning('Verifica que la información proporcionada sea correcta');
            return;
        }

        // Phone and/or email
        if (!data.email.length && data.phone.length !== 10) {
            this.toastrService.warning('Ingresa un correo y/o un teléfono válido');
            return;
        }

        // Create OpenPay and Payment intent token
        this.makingCharge = true;
        const directPayment = {
            customerName: data.cardName,
            customerEmail: data.email,
            customerPhone: data.phone,
            amount: data.amount,
            concept: data.description,
            currency: 'mxn'
        };
        observableForkJoin(
            this.createOpenPayToken(data),
            this.paymentsService.makeDirectPayment(directPayment)
        )
            .subscribe((responses) => {
                const token = responses[0];
                const payment = responses[1];
                this.makePayment({tokenId: token.id, brand: token.card.brand, paymentId: payment.id})
            }, (error) => {
                console.error('Can not create the token', error)
                this.toastrService.warning('No se ha podido validar la información de la tarjeta');
                this.makingCharge = false;
            })
    }

    makePayment(data) {
        const paymentData = {
            orderId: data.paymentId,
            paymentType: 'creditcard',
            onlyRate: false,
            sourceId: data.tokenId,
            deviceId: OpenPay.deviceData.setup(),
            brand: data.brand
        };
        this.paymentsService.processDirectPayment(paymentData)
            .subscribe((response) => {
                this.toastrService.success('¡Se ha realizado el cargo!');
                setTimeout(() => {
                    this.router.navigate(['/cobros']);
                }, 3000);
                this.makingCharge = false;
            }, (error) => {
                console.error('Can not make the charge', error)
                this.toastrService.warning('No se ha podido realizar el cargo');
                this.makingCharge = false;
            });
    }

    createOpenPayToken(data): Observable<any> {
        return Observable.create((observer) => {
            OpenPay.token.create({
                card_number: data.cardNumber,
                holder_name: data.cardName,
                expiration_year: data.cardExpYear,
                expiration_month: data.cardExpMonth,
                cvv2: data.cardCvv
            }, (response) => {
                observer.next(response.data);
                observer.complete();
            }, (error) => {
                observer.error(error.data);
            });
        });
    }

    setupOpenPay() {
        if (!OpenPay || !this.paymentGateways || !this.paymentGateways.openpay) {
            this.toastrService.error('No se ha configurado correctamente OpenPay');
            console.error('No se ha configurado openpay', {external: OpenPay, config: this.paymentGateways});
            return;
        }
        OpenPay.setId(this.paymentGateways.openpay.merchantId);
        OpenPay.setApiKey(this.paymentGateways.openpay.apiKey);
        OpenPay.setSandboxMode(this.paymentGateways.openpay.sandbox);

        this.noGateway = false;
    }
}
