export const environment = {
  production: false,
  service: 'sheyla',
  version: '3.0.0',
  apiUrl: 'https://sandbox-api.muventa.mx',
  landingUrl: 'https://sandbox.sheyla.mx',
  templateUrl: '',
  accessToken: 'mv-eaas-access',
  sessionToken: 'mv-hq',
  accountKey: 'mv-account-id',
  sitebuilderV2Schemas: [
    'abros_sandbox',
    'banstore_sandbox',
    'magnum_sandbox',
    'pinesque',
    'stinkysocks_sandbox',
    'tramayalma_sandbox',
  ],
  plans: {
      basic: 'd8f6d17c384e4e349b6e4c124448701d',
      intermediate: '92550619e761413cb7f51b42fe7c7b1f',
      intermediateplus: '5df71ecfd8f1420bbe9801a7acf7832b',
  },
  bitly: {
      accessToken: '5cc30c9d7be06ee75619e32705bc26bb12c628db',
      api: 'https://api-ssl.bitly.com',
      shortenURI: '/v3/shorten'
  },
  embedded: {
      widget: {
          loader: 'https://checkout.sandbox.muventa.com/embebido/v2/mvta-widget.js',
          checkoutUrl: 'https://checkout.sandbox.muventa.com',
          api: {
              endpoint: 'https://sandbox-api.muventa.mx',
              version: 'v1',
              versionURI: '/api/v1/',
              cart: 'carrito',
              skuLinkURI: '/checkout/v1/p/',
              purchaseURI: '/compra/',
          },
          resources: {
              stylesheet: 'main.css',
              base: 'https://checkout.sandbox.muventa.com/embebido/v2'
          }
      },
      cart: {
          loader: 'https://checkout.sandbox.muventa.com/embebido/v1/mvta-loader.js',
          api: {
              endpoint: 'https://sandbox-api.muventa.mx',
              version: 'v1',
              versionURI: '/api/v1/',
              cart: 'carrito',
              skuLinkURI: '/checkout/v1/p/'
          },
          resources: {
              style: 'mvta',
              builder: 'mvta-builder',
              base: 'https://checkout.sandbox.muventa.com/embebido/v1',
              jQuery: 'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js'
          }
      }
  },
  siteBuilder: {
      urlTemplate: 'https://$schema.sitios.sandbox.muventa.com/',
  },
  keenIO: {
      projectId: '589a65df8db53dfda8a86d37',
      writeKey: '9AFEF5C486D678F9B4F53634E3AE0C85C279C515D6C52609EB6FB60466B008F1D319719955A038842148FAC13F79B7D51F36AD245946B192314200DBB3766420A5646D00A3B45FD80F93BB6D2FCFD55066DF132AF32BF73D89D5DD6763290D55',
      readKey: 'CF2FBC1224EEBC0BABA7570B0D07A0B21F2E6057B94584C2D83C6AFDC6CAFC7983D4ED97424E84275B1A4077D5B72374370B6A1097CB069D7A27165C3ED73206713FCAB842AE4B44391AAD0F1BCF5367FE0CE35262E7DB21EE9C5EF345E463D0'
  },
  paypal: {
      login: {
          returnURL: 'https://sandbox-api.muventa.mx/api/oauth/paypal'
      }
  },
  fbCatalogUrl: 'https://sandbox-api.muventa.mx/api/v1/store/fb-products/$schema',
  muventaIp: '35.193.141.7'
};
