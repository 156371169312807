import { Pipe, PipeTransform } from '@angular/core';
import { DateRange } from './date-range.component';

@Pipe({
  name: 'dateRange'
})

export class DateRangePipe implements PipeTransform {
  transform(range: DateRange, rangeSeparator = ' - ', dateSeparator = '/'): string {
    const from = new Date(range.from);
    const to = new Date(range.to);

    if (!(this.isDate(from) && this.isDate(to))) {
      return '';
    }

    const reformattedFrom = this.formatDate(from, dateSeparator);
    const reformattedTo = this.formatDate(to, dateSeparator);

    return reformattedFrom + rangeSeparator + reformattedTo;
  }

  private padWithZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date, s: string): string {
    return `${this.padWithZero(date.getDate())}${s}${this.padWithZero(date.getMonth())}${s}${this.padWithZero(date.getFullYear())}`;
  }

  private isDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }
}
