import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class FilePickerService {

  constructor(private http: HttpClient) { }

  getDocuments(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/documents`);
  }

  uploadDocument(document, documentName: string): Observable<any> {
    const formData = new FormData();
    formData.append('document', document);
    formData.append('documentName', documentName);
    return this.http.post(environment.apiUrl + '/api/v1/admin/documents', formData);
  }

  deleteDocument(documentName: string) {
    const params = new HttpParams({
      fromObject: {
        documentName,
      }
    });
    return this.http.delete(environment.apiUrl + '/api/v1/admin/documents', { params });
  }
}
