import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { AuthService } from '../auth';
import { CreditCardService } from './credit-card.service';

declare const OpenPay: any;

@Component({
  selector: 'mvta-credit-card-manager',
  templateUrl: 'credit-card-manager.component.html',
  providers: [CreditCardService],
  styleUrls: ['./credit-card-manager.component.scss']
})
export class CreditCardManagerComponent implements OnInit {

  @Output() cardSelected = new EventEmitter();
  @Input() canSelect = true;
  @Input() canAdd = true;
  @Input() disabled = false;
  @Input() paying = false;
  @Input() addButtonText = 'Agregar';

  // Credit Card
  masks = {
    cardNumber: [
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/
    ],
    cardExpiration: [
      /\d/, /\d/,
      ' ', '/', ' ',
      /\d/, /\d/
    ],
    cardCvv: [
      /\d/, /\d/, /\d/, /\d/
    ]
  };
  newCard = {
    cardNumber: '',
    cardName: '',
    cardExpiration: '',
    cardCvv: ''
  };
  addingCard = false;
  adding = false;
  paymentMethods;
  selectedCard;
  loading = true;

  constructor(private creditCardService: CreditCardService, private toastrService: ToastrService,
    private authService: AuthService) {
    this.paymentMethods = [];
  }

  ngOnInit() {
    this.getCards();
    this.getPaymentDetails();
  }

  onSelectCard(card, ev) {
    this.selectedCard = card;
    swal({
      title: 'Ingresa el CVV de tu tarjeta',
      text: '3-4 dígitos al reverso de tu tarjeta',
      type: 'info',
      input: 'text',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      inputValidator: (val) => {
          if (!val || val.length < 3) {
              return 'Ingresa un código de confirmación válido';
          }
          return '';
      }
    }).then((res) => {
      if (res.dismiss || res.value.length < 3) {
        this.paying = false;
        this.selectedCard.paying = false;
        return;
      }
      const emitData = Object.assign({}, this.selectedCard);
      emitData.ccCvv2 = res.value;
      emitData.deviceId = OpenPay.deviceData.setup();
      this.cardSelected.emit(emitData);
    });
  }

  onAddCard(form, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    if (!form || !form.valid) {
      console.log('form!', form);
      this.toastrService.warning('Revisa la información de la tarjeta');
      return;
    }

    const paymentData = this.validateCardInformation();
    if (paymentData === null) {
      return;
    }
    this.adding = true;
    const openPayData = {
      card_number: paymentData.cardNumber,
      holder_name: paymentData.cardName,
      expiration_year: paymentData.expirationYear,
      expiration_month: paymentData.expirationMonth,
      cvv2: paymentData.cardCvv,
    };
    OpenPay.token.create(openPayData, (response) => {
      const tokenId = response.data.id;
      const paymentInput = {
        billingAddress: '',
        paymentMethodId: 'openpay',
        sourceId: tokenId,
        deviceId: OpenPay.deviceData.setup(),
        brand: response.data.card.brand,
        cvv2: paymentData.cardCvv,
        // Filled up in API
        payerName: '',
        payerEmail: '',
        payerId: '',
      };
      this.creditCardService.addCard(paymentInput)
        .subscribe((pmResponse) => {
          this.paymentMethods.push(pmResponse);

          const emitData = Object.assign({}, pmResponse);
          emitData.ccCvv2 = paymentInput.cvv2;
          emitData.deviceId = OpenPay.deviceData.setup();
          this.cardSelected.emit(emitData);
          this.adding = false;
          this.addingCard = false;
        }, (error) => {
          console.error('Can not add the card', error);
          this.toastrService.error('Hubo un problema agregando la tarjeta');
          this.adding = false;
        });
    }, (error) => {
      let additionalInfo = error.data.description || '';
      if (additionalInfo.length > 2) {
        additionalInfo = `<br> (${additionalInfo})`;
      }
      swal({
        title: 'Openpay',
        html: 'Hubo un problema validando tu tarjeta' + additionalInfo,
        type: 'error'
      });
      console.error('Can not create the token', error);
      this.adding = false;
    });
  }

  getPaymentDetails() {
    this.creditCardService.gatewayDetails()
      .subscribe((response) => {
        const gwDetails = response || {};
        if (gwDetails.openpay !== undefined && OpenPay) {
          OpenPay.setId(gwDetails.openpay.merchantId);
          OpenPay.setApiKey(gwDetails.openpay.apiKey);
          OpenPay.setSandboxMode(gwDetails.openpay.sandbox);
        } else {
          this.toastrService.warning('No es posible agregar tarjetas');
        }
      }, (error) => {
        console.error('Can retrieve payment gateway details', error);
        this.toastrService.warning('No es posible agregar tarjetas');
      });
  }

  getCards() {
    this.loading = true;
    this.creditCardService.list()
      .subscribe((response) => {
        this.paymentMethods = response || [];
        this.addingCard = (this.paymentMethods.length === 0);
        this.loading = false;
      }, () => {
        this.paymentMethods = [];
        this.loading = false;
      });
  }

  validateCardInformation() {
    // Validate card
    const paymentData = {
      cardNumber: this.newCard.cardNumber.replace(/ /g, '').replace(/_/g, ''),
      cardName: this.newCard.cardName.trim(),
      cardExpiration: this.newCard.cardExpiration.replace(/ /g, ''), // mm/yy
      cardCvv: this.newCard.cardCvv.trim(),
      expirationMonth: '',
      expirationYear: ''
    };
    if (paymentData.cardNumber.length < 12 || paymentData.cardName.length < 3 || paymentData.cardExpiration.length < 4 ||
      paymentData.cardCvv.length < 3) {
      swal({
        type: 'warning',
        title: 'Información de la tarjeta no válida',
        text: 'Revisa que la información proporcionada de la tarjeta sea válida.'
      });
      this.adding = false;
      return null;
    }
    const dates = paymentData.cardExpiration.split('/');
    paymentData.expirationMonth = dates[0].trim();
    paymentData.expirationYear = dates[1].trim();

    if (!OpenPay) {
      this.toastrService.warning('OpenPay no está disponible');
      this.adding = false;
      return null;
    }

    return paymentData;
  }
}
