import { Directive, TemplateRef } from '@angular/core';
import { DuePaymentState } from './due-payment.state';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[duePaymentModal]'
})
export class DuePaymentModalDirective {
  constructor(itemTemplate: TemplateRef<any>, state: DuePaymentState) {
    state.template = itemTemplate;
  }
}
