
import {of as observableOf,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressesService {

    constructor(private http: HttpClient) { }

    getShippingCountries(): Observable<any> {
        return observableOf([{"version":1,"createdAt":{"epochSecond":1518714868,"nano":754000000},"updatedAt":{"epochSecond":1518714868,"nano":754000000},"createdBy":"anon","modifiedBy":"anon","code":"mex","name":"México","officialName":"Estados Unidos Mexicanos","knownLogistics":[],"regions":[{"code":"ags","name":"Aguascalientes"},{"code":"bcn","name":"Baja California"},{"code":"bcs","name":"Baja California Sur"},{"code":"cam","name":"Campeche"},{"code":"coa","name":"Coahuila"},{"code":"col","name":"Colima"},{"code":"chp","name":"Chiapas"},{"code":"chi","name":"Chihuahua"},{"code":"cmx","name":"Ciudad de México"},{"code":"dur","name":"Durango"},{"code":"gto","name":"Guanajuato"},{"code":"gro","name":"Guerrero"},{"code":"hgo","name":"Hidalgo"},{"code":"jal","name":"Jalisco"},{"code":"mex","name":"Estado De Mexico"},{"code":"mic","name":"Michoacan"},{"code":"mor","name":"Morelos"},{"code":"nay","name":"Nayarit"},{"code":"nle","name":"Nuevo Leon"},{"code":"oax","name":"Oaxaca"},{"code":"pue","name":"Puebla"},{"code":"qro","name":"Queretaro"},{"code":"roo","name":"Quintana Roo"},{"code":"slp","name":"San Luis Potosi"},{"code":"sin","name":"Sinaloa"},{"code":"son","name":"Sonora"},{"code":"tab","name":"Tabasco"},{"code":"tam","name":"Tamaulipas"},{"code":"tlx","name":"Tlaxcala"},{"code":"ver","name":"Veracruz"},{"code":"yuc","name":"Yucatan"},{"code":"zac","name":"Zacatecas"}],"excludedRegions":""}]);
    }
}
