/**
 * A function that emits a side effect and does not return anything.
 */
export type Procedure = (...args: any[]) => void;

export function debounce<F extends Procedure>(
    func: F,
    waitMilliseconds = 250,
    isImmediate = false
): F {
    let timeoutId;
    return function (this: any, ...args: any[]) {
        const context = this;

        const doLater = function () {
            timeoutId = undefined;
            if (!isImmediate) {
                func.apply(context, args);
            }
        }

        const shouldCallNow = isImmediate && timeoutId === undefined;
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(doLater, waitMilliseconds);

        if (shouldCallNow) {
            func.apply(context, args);
        }
    } as any
}
