import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutTranslate = trigger('fadeInOutTranslate', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
    ])
]);

export const flyInOut = trigger('flyInOut', [
    transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate(300)
    ]),
    transition(':leave', [
        animate(300, style({transform: 'translateX(100%)'}))
    ])
]);
