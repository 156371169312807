import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
  selector: 'mvta-element-double-banner-text-editor',
  templateUrl: 'banner-double-text-editor.component.html'
})
export class ThemeEditorDoubleBannerTextComponent implements OnInit {

  ckEditorConfig = {
    removeButtons: 'Source,Save,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,CopyFormatting,RemoveFormat,NumberedList,Outdent,' +
    'Blockquote,BidiLtr,Image,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Format,Font,FontSize,NewPage,' +
    'Preview,Print,PasteText,Paste,Copy,Replace,Redo,PasteFromWord,Checkbox,Radio,TextField,Textarea,Select,Button,' +
    'ImageButton,HiddenField,BulletedList,Indent,CreateDiv,BidiRtl,Anchor,' +
    'Language,Flash,Table,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe,Subscript,Superscript,Strike'
};

  sectionValue;
  debouncedSectionChange;

  @Input()
  get section() {
    return this.sectionValue;
  }

  set section(val) {
    if (val === null || val === undefined || val.isNew) {
      this.sectionValue = this.defaultValues();
      this.sectionValue.isTop = val.isTop || false;
      return;
    }
    this.sectionValue = val;
    if (this.sectionValue.images.length === 0) {
      this.sectionValue.images.push({
        image: '',
        title1: '',
        title2: '',
      });
    }
  }

  @Output()
  goBack = new EventEmitter();

  @Output()
  sectionChange = new EventEmitter();

  @Output()
  sectionDelete = new EventEmitter();

  constructor(private sectionService: ThemeEditorSectionConfigService) {
  }

  ngOnInit() {
    this.debouncedSectionChange = debounce(() => {
      this.sectionChange.emit(this.sectionValue);
    }, 800);
    if (this.sectionValue.isNew) {
      this.sectionChange.emit(this.sectionValue);
    }
  }

  onSectionChange() {
    this.debouncedSectionChange();
  }

  onGoBack(ev) {
    this.goBack.emit(ev);
  }

  onDeleteSection() {
    this.sectionDelete.emit(this.sectionValue);
  }

  defaultValues() {
    return {
      type: 'double-banner-text-editor',
      isNew: true,
      id: this.sectionService.getRandomId(),
      settings: {

      }
    };
  }
}
