import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mvta-arrow-box',
  templateUrl: './arrow-box.component.html',
  styleUrls: ['arrow-box.component.scss']
})

export class ArrowBoxComponent implements OnInit {
  @Input() arrowPosition: 'top' | 'right' | 'bottom' | 'left' = 'right';
  @Input() backgroundColor = 'white';
  @Input() borderColor = '#ced4da';

  constructor() { }

  ngOnInit() { }
}
