import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemeEditorSectionConfigService } from './sections.service';
import { debounce } from '../../shared/elements/debounce';

@Component({
  selector: 'mvta-theme-editor-banner-doble',
  templateUrl: './banner-doble.component.html',
  styleUrls: ['./banner-doble.component.scss']
})
export class ThemeEditorBannerDoubleConfigComponent implements OnInit {

  sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    imagesForBanner = [];
    addingImages = false;

    originalImage;

    imageToAdd = {
        image: '',
        title1: '',
        title2: '',
        action: ''
    }
    mode = '';

    constructor(private sectionService: ThemeEditorSectionConfigService) { }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    defaultValues() {
        return {
            type: 'doublebanner',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: {
                image: '',
                title: 'Titulo',
                subtitle: 'Subtitulo',
                buttonTitle: 'boton',
                buttonLink: '/tienda.html?categoria=verano',
            }
        };
    }

    onAddImage() {

        if (this.mode === 'edit') {
            const idx = this.sectionValue.images.indexOf(this.originalImage);
            this.sectionValue.images[idx] = this.imageToAdd;
        } else {
            this.sectionValue.images.push(this.imageToAdd);
        }
        this.addingImages = false;
        this.mode = '';
        this.originalImage = '';
        this.imageToAdd  = {
            image: '',
            title1: '',
            title2: '',
            action: ''
        }
        this.debouncedSectionChange();
    }

    onEditImage(image, ev) {

        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        this.originalImage = image;
        this.imageToAdd = Object.assign({}, image);
        this.addingImages = true;
        this.mode = 'edit';
    }

    onAddNewImage() {
        this.addingImages = true;
        this.mode = 'add';
    }

    onRemoveImage() {
        const idx = this.sectionValue.images.indexOf(this.originalImage);
        if (idx > -1) {
            this.sectionValue.images.splice(idx, 1);
        }
        this.mode = '';
        this.originalImage = '';
        this.addingImages = false;
        this.imageToAdd  = {
            image: '',
            title1: '',
            title2: '',
            action: ''
        }
        this.debouncedSectionChange();
    }

}
