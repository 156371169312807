import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'mvta-template-preview',
  templateUrl: 'template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit, OnDestroy {

  qParamsObserver;
  templateId = '';
  templates = [];
  template;
  templateUrl;
  loading = true;

  constructor(private http: HttpClient, private route: ActivatedRoute, private toastrService: ToastrService,
    private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.qParamsObserver = this.route.queryParams
      .subscribe((qParams) => {
        this.templateId = qParams.id || '';
        this.searchAndShow();
      });

    this.getTemplates()
      .subscribe((response) => {
        this.templates = response || [];
        if (this.templates.length === 0) {
          this.toastrService.success('No se ha podido cargar la demostración. Intenta de nuevo por favor');
          this.router.navigate(['/sitebuilder/plantillas-adicionales'], {queryParams: {plantilla: this.templateId || ''}});
          return;
        }
        this.searchAndShow();
      }, () => {
        this.templates = [];
      });
  }

  searchAndShow() {
    if (this.templateId.length > 0 && this.templates.length > 0) {
      const templates = [].concat.apply([], this.templates.map((t) => t.templates));
      this.template = templates.find((t) => t.id === this.templateId);
      if (this.template === undefined) {
        this.toastrService.success('No se ha podido cargar la demostración. Intenta de nuevo por favor');
        this.router.navigate(['/sitebuilder/plantillas-adicionales'], {queryParams: {plantilla: this.templateId || ''}});
        return;
      }
      this.templateUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.template.demoUrl);
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.qParamsObserver.unsubscribe();
  }

  getTemplates(): Observable<any> {
    return this.http.get(`/assets/templates.json`);
  }
}
