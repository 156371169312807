import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentInput } from './payment-input.interface';

@Injectable()
export class CreditCardService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/payment-methods`);
  }

  addCard(input: PaymentInput): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/payment-method`, input);
  }

  gatewayDetails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/account/muventa-payments`);
  }
}
