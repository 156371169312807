import { PaymentsService } from './../payments.service';
import { Component, OnInit } from '@angular/core';
import { fadeInOutTranslate } from '../../shared/elements/animation';
import { DirectPayment } from '../payment.interface';

@Component({
    selector: 'mvta-payments-list',
    templateUrl: './list.component.html',
    providers: [PaymentsService],
    animations: [fadeInOutTranslate]
})
export class PaymentsListComponent implements OnInit {

    payments: Array<DirectPayment>;
    loadingPayments = false;
    constructor(private paymentsService: PaymentsService) { }

    ngOnInit() {
        this.getPayments();
    }

    getPayments() {
        this.loadingPayments = true;
        this.paymentsService.getAll()
            .subscribe((response) => {
                this.payments = response;
                this.loadingPayments = false;
            }, (error) => {
                console.error('Can not get payments list', error);
                this.loadingPayments = false;
            })
    }
}
