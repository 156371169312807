import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mvta-custom-check',
  templateUrl: 'custom-check.component.html',
  styleUrls: ['custom-check.component.scss'],
})

export class CustomCheckComponent implements OnInit {

  _active: boolean;
  _states: { active, nonActive };
  _customLabel: string;

  @Input() set active(val: boolean) {
    this._active = val;
  }
  @Input() set states(val: { active, nonActive }) {
    this._states = val;
  }
  @Input() set customLabel(val: string) {
    this._customLabel = val;
  }
  @Input() withCustomLabel = false;
  @Output() customAction = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  clickFunction(event) {

  }

}
