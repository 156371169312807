import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ChartModule } from 'angular2-chartjs';
import { TextMaskModule } from 'angular2-text-mask';
import { OrderPaymentDetailsComponent } from 'app/logistics/orders/detail/order-payment-details/order-payment-details.component';
import { ThemeEditorAddSectionConfigComponent } from 'app/theme-editor/sections/add.component';
import { ThemeEditorBannerDoubleConfigComponent } from 'app/theme-editor/sections/banner-doble.component';
import { ThemeEditorDoubleBannerTextComponent } from 'app/theme-editor/sections/banner-double-text-editor.component';
import { ThemeEditorBannerRectangularComponent } from 'app/theme-editor/sections/banner-title.component';
import { ThemeEditorBannerConfigComponent } from 'app/theme-editor/sections/banner.component';
import { ThemeEditorBlockTextConfigComponent } from 'app/theme-editor/sections/block-text.component';
import { ThemeEditorBlockTitleConfigComponent } from 'app/theme-editor/sections/block-title.component';
import { ThemeEditorContactInfoConfigComponent } from 'app/theme-editor/sections/contact-info.component';
import { ThemeEditorContainer3xVerticalTitle3Subtitle3Component } from 'app/theme-editor/sections/container3x-vertical-title3-subtitle3.component';
import { ThemeEditorFooterConfigComponent } from 'app/theme-editor/sections/footer.component';
import { ThemeEditorHeaderConfigComponent } from 'app/theme-editor/sections/header.component';
import { ThemeEditorImageConfigComponent } from 'app/theme-editor/sections/image.component';
import { ThemeEditorItemsCategoryConfigComponent } from 'app/theme-editor/sections/items-category.component';
import { ThemeEditorItemsNxRowConfigComponent } from 'app/theme-editor/sections/itemsnxrow.component';
import { ThemeEditorPageBannerConfigComponent } from 'app/theme-editor/sections/page-banner.component';
import { ThemeEditorPaymentMethodsConfigComponent } from 'app/theme-editor/sections/payment-methods.component';
import { ThemeEditorQuoteConfigComponent } from 'app/theme-editor/sections/quote.component';
import { ThemeEditorTemplateColorConfigComponent } from 'app/theme-editor/sections/template-color.component';
import { ThemeEditorWelcomeAreaConfigComponent } from 'app/theme-editor/sections/welcome-area.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { CKEditorModule } from 'ng2-ckeditor';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { UiSwitchModule } from 'ng2-ui-switch/dist';
import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { AddressesComponent } from './addresses/addresses.component';
import { ArrowBoxComponent } from './arrow-box/arrow-box.component';
import { AccountService, AuthService } from './auth';
import { CardRefreshDirective } from './card/card-refresh.directive';
import { CardToggleDirective } from './card/card-toggle.directive';
import { CardComponent } from './card/card.component';
import { ContentPageComponent } from './content-page/content-page.componente';
import { CreditCardManagerComponent } from './credit-card-manager/credit-card-manager.component';
import { CustomCheckComponent } from './custom-check/custom-check.component';
import { InputEditComponent } from './custom-input-edit-text/custom-input-edit-text.component';
import { DateRangeHiddenComponent } from './date-range-hidden/date-range-hidden.component';
import { DateRangePickerComponent } from './date-range/date-range.component';
import { DateRangePipe } from './date-range/date-range.pipe';
import { DuePaymentModalComponent } from './due-payment/due-payment.component';
import { DuePaymentModalDirective } from './due-payment/due-payment.directive';
import { BytesPipe } from './elements/bytes.pipe';
import { DataFilterPipe } from './elements/data-filter.pipe';
import { FocusDirective } from './elements/focus.directive';
import { GravatarDirective } from './elements/gravatar.directive';
import { ImgFallbackDirective } from './elements/img-fallback.directive';
import { KeysPipe } from './elements/keys.pipe';
import { SafeHtmlPipe } from './elements/safe-html.pipe';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { HoldableDirective } from './holdable/holdable.directive';
import { InterceptorModule } from './http/interceptor';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { ImageCropperComponent } from './img-cropper/img-cropper.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MenuItems } from './menu-items/menu-items';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { TidioService } from './tidio.service';
import { ArrayIndexesPipe } from './utils/array-indexes.pipe';
import { FindInArrayPipe } from './utils/find-in-array.pipe';
import { NumberInsideParenthesesPipe } from './utils/number-in-parentheses.pipe';
import { SplitArrayPipe } from './utils/split-array.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    CKEditorModule,
    TagInputModule,
    UiSwitchModule,
    FileUploadModule,
    ChartModule,
    ClickOutsideModule,
    InterceptorModule,
    ColorPickerModule,
    ClipboardModule,
    TextMaskModule,
    ShareButtonsModule.forRoot(),
    NgxDatatableModule,
    ImageCropperModule,
    NgxDnDModule,
    LyButtonModule,
    LyIconModule,
    LyResizingCroppingImageModule,
    LyThemeModule.setTheme('minima-light'),
    LyToolbarModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    CardComponent,
    ImgFallbackDirective,
    SpinnerComponent,
    ModalBasicComponent,
    DataFilterPipe,
    SplitArrayPipe,
    KeysPipe,
    FindInArrayPipe,
    DateRangePipe,
    NumberInsideParenthesesPipe,
    ArrayIndexesPipe,
    BytesPipe,
    DateRangeHiddenComponent,
    LoadingIndicatorComponent,
    GravatarDirective,
    FocusDirective,
    AddressesComponent,
    CreditCardManagerComponent,
    ImagePickerComponent,
    IconPickerComponent,
    FilePickerComponent,
    DuePaymentModalComponent,
    DuePaymentModalDirective,
    HoldableDirective,
    ImageCropperComponent,
    ContentPageComponent,
    CustomCheckComponent,
    InputEditComponent,
    ArrowBoxComponent,

    // Template
    ThemeEditorWelcomeAreaConfigComponent,
    ThemeEditorBannerConfigComponent,
    ThemeEditorPageBannerConfigComponent,
    ThemeEditorItemsCategoryConfigComponent,
    ThemeEditorImageConfigComponent,
    ThemeEditorBlockTextConfigComponent,
    ThemeEditorBlockTitleConfigComponent,
    ThemeEditorBannerRectangularComponent,
    ThemeEditorDoubleBannerTextComponent,
    ThemeEditorQuoteConfigComponent,
    ThemeEditorHeaderConfigComponent,

    ThemeEditorContactInfoConfigComponent,
    ThemeEditorFooterConfigComponent,
    ThemeEditorAddSectionConfigComponent,
    ThemeEditorTemplateColorConfigComponent,
    ThemeEditorPaymentMethodsConfigComponent,

    /* mtva-layouts */
    ThemeEditorBannerDoubleConfigComponent,
    ThemeEditorItemsNxRowConfigComponent,
    ThemeEditorContainer3xVerticalTitle3Subtitle3Component,

    OrderPaymentDetailsComponent,
    DateRangePickerComponent,
    SafeHtmlPipe,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    CardComponent,
    SpinnerComponent,
    NgbModule,
    CKEditorModule,
    FormsModule,
    ImgFallbackDirective,
    ReactiveFormsModule,
    ModalBasicComponent,
    TagInputModule,
    UiSwitchModule,
    DataFilterPipe,
    SplitArrayPipe,
    KeysPipe,
    FindInArrayPipe,
    DateRangeHiddenComponent,
    DateRangePipe,
    NumberInsideParenthesesPipe,
    ArrayIndexesPipe,
    FileUploadModule,
    Ng2GoogleChartsModule,
    ChartModule,
    ClickOutsideModule,
    ColorPickerModule,
    ClipboardModule,
    TextMaskModule,
    LoadingIndicatorComponent,
    GravatarDirective,
    FocusDirective,
    AddressesComponent,
    ShareButtonsModule,
    NgxDatatableModule,
    ImageCropperModule,
    NgxDnDModule,
    CreditCardManagerComponent,
    ImagePickerComponent,
    IconPickerComponent,
    FilePickerComponent,
    DuePaymentModalComponent,
    DuePaymentModalDirective,
    HoldableDirective,
    ImageCropperComponent,
    ContentPageComponent,
    CustomCheckComponent,
    InputEditComponent,
    ArrowBoxComponent,

    // Template
    ThemeEditorWelcomeAreaConfigComponent,
    ThemeEditorBannerConfigComponent,
    ThemeEditorPageBannerConfigComponent,
    ThemeEditorItemsCategoryConfigComponent,
    ThemeEditorImageConfigComponent,
    ThemeEditorBlockTextConfigComponent,
    ThemeEditorQuoteConfigComponent,
    ThemeEditorHeaderConfigComponent,
    ThemeEditorBlockTitleConfigComponent,
    ThemeEditorBannerRectangularComponent,
    ThemeEditorDoubleBannerTextComponent,

    ThemeEditorContactInfoConfigComponent,
    ThemeEditorFooterConfigComponent,
    ThemeEditorAddSectionConfigComponent,
    ThemeEditorTemplateColorConfigComponent,
    ThemeEditorPaymentMethodsConfigComponent,

    /* mtva-layouts */
    ThemeEditorBannerDoubleConfigComponent,
    ThemeEditorItemsNxRowConfigComponent,
    ThemeEditorContainer3xVerticalTitle3Subtitle3Component,
    OrderPaymentDetailsComponent,
    DateRangePickerComponent,
    SafeHtmlPipe,
  ],
  providers: [
    MenuItems,
    AuthService,
    AccountService,
    CurrencyPipe,
    TidioService,
    { provide: LY_THEME, useClass: MinimaLight, multi: true }
  ]
})
export class SharedModule { }
