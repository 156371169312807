import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';

@Component({
  selector: 'mvta-theme-container3x-vertical-title3-subtitle3',
  templateUrl: './container3x-vertical-title3-subtitle3.component.html',
  styleUrls: ['./container3x-vertical-title3-subtitle3.component.scss']
})
export class ThemeEditorContainer3xVerticalTitle3Subtitle3Component implements OnInit, OnChanges, OnDestroy, AfterViewInit  {

  sectionValue;

  debouncedSectionChange;

  changesObserver;

  @ViewChild('formContainer3xVerticalTitle3Subtitle3') form;

  @Input()
    get section() {
        return this.sectionValue;
    }

  set section(val) {
    if (val === null || val === undefined || val.isNew) {
        this.sectionValue = this.defaultValues();
        this.sectionValue.isTop = val.isTop || false;
        return;
    }
    this.sectionValue = val;
}

@Output()
goBack = new EventEmitter();

@Output()
sectionChange = new EventEmitter();

@Output()
sectionDelete = new EventEmitter();

  constructor() {
    this.sectionValue = this.defaultValues();
  }

  ngOnChanges() {
    if (this.sectionValue !== undefined && this.sectionValue !== null) {
      this.emitChanges();
    }
  }

  emitChanges() {
    this.sectionChange.emit(this.sectionValue);
  }

  ngAfterViewInit() {
    this.changesObserver = this.form.control.valueChanges
    .subscribe(() => {
      this.sectionChange.emit(this.sectionValue);
      });
    }

  ngOnInit() {
    this.debouncedSectionChange = debounce(() => {
      this.sectionChange.emit(this.sectionValue);
  }, 800);
  if (this.sectionValue.isNew) {
      this.sectionChange.emit(this.sectionValue);
  }
  }

  ngOnDestroy() {
    this.changesObserver.unsubscribe();
  }

  onGoBack(ev) {
    this.goBack.emit(ev);
  }

  defaultValues() {
  return {
      /* isNew: true, */
      /* id: this.sectionService.getRandomId(), */
      settings: {
          type: 'container3x-vertical-title3-subtitle3',
          title1: 'Direccion',
          subtitle1: 'SubTítulo1',
          title2: 'Telefono',
          subtitle2: 'SubTítulo2',
          title3: 'Correo Contacto',
          subtitle3: 'SubTítulo3',
        }
    };
  }
}
