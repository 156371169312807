import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { BytesPipe } from '../elements/bytes.pipe';
import { FilePickerService } from './file-picker.service';

@Component({
  selector: 'mvta-file-picker',
  templateUrl: 'file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  providers: [FilePickerService, BytesPipe],
})
export class FilePickerComponent implements AfterViewInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() src: string;
  @Output() srcChange = new EventEmitter();

  @Input() showRemove = true;
  @Input() showChange = true;
  @Input() showInstructions = true;
  @Input() changeText = 'Cambiar';
  @Input() removeText = 'Quitar';
  @Input() cancelSelectionText = 'Cancelar';
  @Input() showUploaded = true;

  @Input() maxFileSize = 4 * 1024 * 1024; // 4 MB
  @Input() allowedFileExtensions = '.pdf, .doc, .docx, .xls, .xlsx, .csv';

  uploading = false;
  loadingFiles = false;
  changed = false;
  files = [];
  faFilePdf = faFilePdf;

  constructor(
    private filePickerService: FilePickerService,
    private toastrService: ToastrService,
    private bytesPipe: BytesPipe,
  ) { }

  ngAfterViewInit() {
    if (this.showUploaded) {
      this.getDocuments();
    }
  }

  getDocuments() {
    this.loadingFiles = true;
    this.filePickerService.getDocuments()
        .subscribe((response) => {
          this.files = (response.documents || [])
            .map((i) => {
              return {
                src: i.link,
                name: this.getNameFromLink(i.link),
              };
            });
          this.loadingFiles = false;
        }, (error) => {
          this.files = [];
          this.loadingFiles = false;
        });
  }

  getNameFromLink(link: string): string {
    const lastSlash = link.lastIndexOf('/');
    if (lastSlash === -1) {
      return link;
    }
    return link.substr(lastSlash + 1);
  }

  onDeleteFile() {
    this.src = '';
    this.srcChange.emit(this.src);
  }

  onSelectFile(ev) {
    this.src = ev;
    this.srcChange.emit(this.src);
  }

  onFileChange(files) {
    if (files.length > 1) {
      swal({
        title: 'Subir Archivo',
        text: 'Solo se puede subir un archivo.'
      });
      return;
    }

    const file = files[0];
    if (file.size >= this.maxFileSize) {
      const sizeInMb = this.bytesPipe.transform(file.size, 2, 'B', 'MB');
      swal({
        title: 'Subir Archivo',
        html: `El tamaño máximo de archivos es de <span class="font-weight-bold">4 MB</span>,
          el proporcionado es de <span class="font-weight-bold">${sizeInMb}</span> por favor optimiza tu archivo.`,
        type: 'warning'
      });
      return;
    }
    this.uploading = true;
    this.doUploadDocument(file, file.name);
  }

  doUploadDocument(file, documentName) {
    this.filePickerService.uploadDocument(file, documentName)
      .subscribe((response) => {
        this.files.push({ src: response.fileName, name: this.getNameFromLink(response.fileName) });
        this.resetFileUpload();
      }, () => {
        swal({
          title: 'Subir Archivo',
          text: 'Hubo un problema subiendo el archivo, por favor revisa el formato',
          type: 'warning'
        });
        this.resetFileUpload();
      });
  }

  resetFileUpload() {
    this.uploading = false;
    if (this.fileInput) {
      console.log(this.fileInput.nativeElement);
      this.fileInput.nativeElement.value = '';
    }
    // this.changed = true;
    // setTimeout(() => {
    //   this.changed = false;
    // }, 500);
  }

  onRemoveFileFromCloud(file, ev) {
    if (ev && ev.stopPropagation) {
      ev.stopPropagation();
    }

    const name = file.src.split('muventa/')[1];
    this.filePickerService.deleteDocument(name)
      .subscribe((r) => {
        const idx = this.files.indexOf(file);
        if (idx > -1) {
          this.files.splice(idx, 1);
        }
        this.toastrService.success('Se ha eliminado el archivo')
      }, (e) => {
        console.log(e);
      })
  }
}
