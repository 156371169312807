import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    private jwtHelper: JwtHelperService;

    constructor(private router: Router, private authService: AuthService) {
        this.jwtHelper = new JwtHelperService();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const token: string = sessionStorage.getItem(environment.sessionToken) || '';
        const accountToken: string = sessionStorage.getItem(environment.accessToken) || '';

        if (token.length > 10 && !this.jwtHelper.isTokenExpired(token) &&
            accountToken.length > 10 && !this.jwtHelper.isTokenExpired(accountToken)) {
            return true;
        }

        this.authService.redirectUrl = state.url;
        this.router.navigate(['/auth/login']);

        return false;
    }
}
