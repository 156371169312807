import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from './dateAdapter.service';
import { DMYFormatter } from './dmyFormatter.service';

export interface DateRange {
  from: string;
  to: string;
};
@Component({
  selector: 'mvta-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: NgbDateParserFormatter, useClass: DMYFormatter },
    // Ver https://angular.io/api/core/ClassProvider para usar múltiples clases
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  @HostBinding('class.form-row') formRow = true;
  @Input() fromText: string;
  @Input() toText: string;

  from: NgbDate;
  to: NgbDate;

  @Input() date: DateRange | null;
  @Input() prepend = false;

  @Output() dateRange: EventEmitter<DateRange> = new EventEmitter();

  hoveredDate: NgbDate | null;
  constructor(
    private dateAdapter: NgbDateAdapter<string>,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {}


  ngOnInit(): void {
    if (!this.date || !this.date.from|| !this.date.to) {
      this.from = this.calendar.getToday();
      this.to = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    }
    this.dateRange.emit({
      from: this.dateAdapter.toModel(this.from),
      to: this.dateAdapter.toModel(this.to),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.from = NgbDate.from(this.dateAdapter.fromModel(changes.date.currentValue.from));
      this.to = NgbDate.from(this.dateAdapter.fromModel(changes.date.currentValue.to));
    }
  }

  onDateSelection(date: NgbDate): void {
    if (!this.from && !this.to) {
      this.from = date;
    } else if (
      this.from &&
      !this.to &&
      date &&
      (date.after(this.from) || date.equals(this.from))
    ) {
      this.to = date;
      this.dateRange.emit({
        from: this.dateAdapter.toModel(this.from),
        to: this.dateAdapter.toModel(this.to),
      });
    } else {
      this.to = null;
      this.from = date;
    }
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.from &&
      !this.to &&
      this.hoveredDate &&
      date.after(this.from) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return this.to && date.after(this.from) && date.before(this.to);
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.from) ||
      (this.to && date.equals(this.to)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
}
