import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class ImagePickerService {
    constructor(private http: HttpClient) { }

    getImages(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/admin/images`);
    }

    uploadImage(imageData: string): Observable<any> {
        const formData = new FormData();
        formData.append('image', imageData);

        return this.http.post(environment.apiUrl + '/api/v1/admin/image', formData);
    }

    deleteImage(image) {
        const qParams = new HttpParams({
            fromObject: {
                name: image
            }
        })
        return this.http.delete(environment.apiUrl + '/api/v1/admin/image', {params: qParams});
    }

}
