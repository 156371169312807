import { Angulartics2KeenIO } from './shared/analytics/angulartics2-keenio';
import { Component } from '@angular/core';

@Component({
    selector: 'mvta-root',
    template: '<router-outlet><spinner></spinner></router-outlet>'
})
export class AppComponent {
    constructor(angulartics2KeenIO: Angulartics2KeenIO) { }
}
