import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { CleanLayoutComponent } from './layouts/clean/clean-layout.component';
import { OrderPrintComponent } from './logistics/orders/print/print.component';
import { PaymentsListComponent } from './payments/list/list.component';
import { PaymentsMakeComponent } from './payments/make/make.component';
import { AuthGuard, FeaturesGuard } from './shared/auth';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        loadChildren: './home/home.module#HomeModule',
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'carritos',
        loadChildren: './carts/carts.module#CartsModule',
      },
      {
        path: 'cobros/nuevo',
        component: PaymentsMakeComponent,
        data: {
          breadcrumb: 'Realizar Cobro',
        },
      },
      {
        path: 'cobros/',
        redirectTo: 'cobros',
        pathMatch: 'full',
      },
      {
        path: 'cobros',
        component: PaymentsListComponent,
        data: {
          breadcrumb: 'Lista de Cobros',
        },
      },
      {
        path: 'reportes',
        loadChildren: './reports/reports.module#ReportsModule',
      },
      {
        path: 'clientes',
        loadChildren: './customers/customers.module#CustomersModule',
      },
      {
        path: 'lealtad',
        loadChildren: './loyalty/loyalty.module#LoyaltyModule',
      },
      {
        path: 'integraciones',
        loadChildren: './integrations/integrations.module#IntegrationsModule',
      },
      {
        path: 'cuenta',
        loadChildren: './account/account.module#AccountModule',
      },
      {
        path: 'productos',
        loadChildren: './products/products.module#ProductsModule',
      },
      {
        path: 'content-manager',
        loadChildren:
          './content-manager/content-manager.module#ContentManagerModule',
      },
      {
        path: 'widget',
        loadChildren: './widget/widget.module#WidgetModule',
      },
      /* {
            path: 'temas',
            component: ThemesComponent,
            canActivate: [FeaturesGuard],
            data: {
                breadcrumb: 'Temas',
                section: 'website'
            }
        } */
      {
        path: 'sitebuilder',
        loadChildren: './site-builder/site-builder.module#SiteBuilderModule',
      },
      {
        path: 'logistica',
        loadChildren: './logistics/logistics.module#LogisticsModule',
      },
      {
        path: 'eventos',
        loadChildren: './events/events.module#EventsModule',
      },
    ],
  },
  {
    path: '',
    component: CleanLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
      },
      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule',
      },
      {
        path: 'landing',
        loadChildren: './landing/landing.module#LandingModule',
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren:
          './maintenance/offline-ui/offline-ui.module#OfflineUiModule',
      },
      // {
      //   path: 'tema',
      //   canActivate: [AuthGuard],
      //   loadChildren: './theme-editor/theme-editor.module#ThemeEditorModule',
      // },
      {
        path: 'template-editor',
        canActivate: [FeaturesGuard],
        data: {
          require: ['hasPlan'],
        },
        loadChildren: './template-editor/template-editor.module#TemplateEditorModule',
      },
      {
        path: 'website-builder',
        canActivate: [FeaturesGuard],
        data: {
          require: ['hasPlan'],
        },
        loadChildren: './website/website.module#WebsiteModule',
      },
      {
        path: 'print/pedido/:id',
        component: OrderPrintComponent,
      },
      // {
      //   path: 'plantilla',
      //   component: TemplatePreviewComponent,
      //   data: {
      //     breadcrumb: 'Demostración de la plantilla',
      //   },
      // },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
