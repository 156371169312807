import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagsAndCategories } from 'app/website/website.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth';
import { Bundle } from './bundles/bundle.interface';
import { ProductCustomField } from './custom-fields/custom-field.interface';
import { ProductPriceValidityInOrderPolicy } from './order-prices-policy/product-price-validity-in-order-policy.interface';
import { Variant } from './variant.interface';

@Injectable()
export class ProductsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  // ----- Products endpoints ----
  findBySlug(slug: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/product/slug/' + slug);
  }

  findById(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/product/${id}`);
  }

  create(data: Object): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/product', data);
  }

  update(data: Object): Observable<any> {
    delete data['createdAt'];
    delete data['updatedAt'];

    return this.http.put(`${environment.apiUrl}/api/v1/admin/product`, data);
  }

  patchProduct(productId: string, props): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/admin/product/${productId}`, props);
  }

  patchSku(sku: string, props): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/admin/variant/${sku}`, props);
  }

  uploadBulk(csv, zip): Observable<any> {
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('zip', zip);

    return this.http.post(environment.apiUrl + '/api/v1/admin/product/bulk', formData);
  }

  previewBulkUploadChanges(csv): Observable<any> {
    const formData = new FormData();
    formData.append('csv', csv);

    return this.http.post(environment.apiUrl + '/api/v1/admin/product/bulk/preview', formData);
  }

  uploadImage(productId: string, imageData: string): Observable<any> {
    const formData = new FormData();
    formData.append('productId', productId);
    formData.append('image', imageData);

    return this.http.post(environment.apiUrl + '/api/v1/admin/product/image', formData);
  }

  images(productId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/product/' + productId + '/images');
  }

  deleteImage(productId: string, name: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/api/v1/admin/product/' + productId + '/image?name=' + encodeURI(name));
  }

  getStoreProducts(q: string = '', onlyArchived = false, page: number = 0, size: number = 100): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        q: encodeURI(q),
        onlyArchived: String(onlyArchived),
        page: String(page),
        size: String(size)
      }
    });
    return this.http.get(`${environment.apiUrl}/api/v1/admin/store/products`, { params: qParams });
  }


  updateStoreProduct(storeProduct): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/store/product`, storeProduct);
  }

  archiveVariant(sku): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/variant/${sku}/archive`,
      {}
    );
  }

  updateStoreVariant(storeProduct): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/store/variant`, storeProduct);
  }

  updateStoreVariantStock(storeProduct): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/store/stock`, storeProduct);
  }

  createNewBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/bundle`, bundle);
  }
  updateBundleDetails(bundleId: string, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/admin/bundle/${bundleId}/details`, data);
  }
  updateBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.put(`${environment.apiUrl}/api/v1/admin/bundle/${bundle.productId}`, bundle);
  }
  getAllBundles(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/store/bundles`);
  }
  getBundleBySlug(slug: string, includeItems: boolean = false): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        includeItems: String(includeItems)
      }
    });
    return this.http.get(`${environment.apiUrl}/api/v1/store/bundle/${slug}`, { params: qParams });
  }

  getSkus(list: String[]): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        onlyEnabled: 'false'
      }
    })
    return this.http.post(`${environment.apiUrl}/api/v1/store/sku-list`, list, { params: qParams });
  }

  // ---- Variant endpoints ----
  variantsForProduct(term: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/variants?product=' + term);
  }

  findOneVariant(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/variant/' + id);
  }

  createVariant(data: Variant): Observable<any> {
    delete data['createdAt'];
    delete data['updatedAt'];

    return this.http.post(environment.apiUrl + '/api/v1/admin/variant', data);
  }

  createVariants(variants: Array<Variant>): Observable<any> {
    const data = variants.map((v) => {
      delete v.createdAt;
      delete v.updatedAt;
      delete v.toCreate;

      return v;
    })

    return this.http.post(environment.apiUrl + '/api/v1/admin/variants', data);
  }

  updateVariant(variant: Variant): Observable<any> {
    delete variant['createdAt'];
    delete variant['updatedAt'];

    return this.http.put(environment.apiUrl + '/api/v1/admin/variant', variant);
  }

  deleteVariant(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/api/v1/admin/variant/' + id);
  }

  setMasterVariant(sku: string, productId: string): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/v1/admin/variant/master', { sku: sku, productId: productId });
  }

  // ---- Category endpoints ----
  getCategoryTree() {
    return this.http.get(environment.apiUrl + '/api/v1/category-tree?ctype=default&status=all');
  }

  getCollections(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/product/collections');
  }

  getCategorization(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/product/categorization'
    );
  }

  getTagsAndCategories(): Observable<TagsAndCategories> {
    return this.http.get<TagsAndCategories>(`${environment.apiUrl}/api/v1/tags-and-categories`);
  }

  // --- Custom Fields ----
  getAvailableCustomFields(): Observable<ProductCustomField[]> {
    return this.http.get<ProductCustomField[]>(`${environment.apiUrl}/api/v1/admin/variants/available-custom-fields`);
  }

  getAvailableCustomFieldsForSKU(sku: string): Observable<ProductCustomField[]> {
    return this.http.get<ProductCustomField[]>(`${environment.apiUrl}/api/v1/admin/variant/${sku}/available-custom-fields`);
  }

  getCustomFieldsForSKU(sku: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/variant/${sku}/custom-fields`);
  }

  addCustomField(customField: ProductCustomField): Observable<ProductCustomField> {
    return this.http.post<ProductCustomField>(`${environment.apiUrl}/api/v1/admin/variants/custom-field`, customField);
  }

  patchCustomFields(sku: string, customFields: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/admin/variant/${sku}/custom-fields`, customFields);
  }

  uploadFile(file): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}/api/v1/admin/file`, data);
  }

  // ---- Stock Endpoints ----
  getStock(warehouse: string, q: string, page: number = 0, size: number = 100): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        q: String(q),
        page: String(page),
        size: String(size),
      }
    })
    return this.http.get(`${environment.apiUrl}/api/v1/stock/${warehouse}`, { params: qParams });
  }

  setStock(account: string, sku: string, qty: number): Observable<any> {
    return this.http.post(environment.apiUrl + `/api/v1/stock/update/${account}/${sku}/${qty}`, {});
  }

  getWarehouses(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/warehouses`);
  }

  // ---- Embedded Products Endpoints ----
  getEmbeddedProductsLayout(name: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/embeddable-layout/${name}`);
  }

  addEmbeddedProductsLayout(embedded, createJWT = false): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/embeddable-layout?createJWT=${String(createJWT)}`, embedded);
  }

  getProductPriceValidityInOrderPolicy(): Observable<ProductPriceValidityInOrderPolicy> {
    return this.http.get<ProductPriceValidityInOrderPolicy>(`${environment.apiUrl}/api/v1/account/policy/product_price_validity_in_order`);
  }

  setProductPriceValidityInOrderPolicy(policy: ProductPriceValidityInOrderPolicy): Observable<ProductPriceValidityInOrderPolicy> {
    return this.http.put<ProductPriceValidityInOrderPolicy>(
      `${environment.apiUrl}/api/v1/account/policy/product_price_validity_in_order`,
      policy
    );
  }

  // ---- External Endpoints ----
  bitlyShorten(url: string): Observable<any> {
    const encodedURL = encodeURIComponent(url);
    return this.http.jsonp(
      `${environment.bitly.api}${environment.bitly.shortenURI}?` +
      `access_token=${environment.bitly.accessToken}&longUrl=${encodedURL}`,
      'callback'
    );
  }
}
