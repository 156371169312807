import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import swal from 'sweetalert2';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
    selector: 'mvta-theme-editor-header',
    templateUrl: 'header.component.html'
})
export class ThemeEditorHeaderConfigComponent implements OnInit {
    sectionValue;
    debouncedSectionChange;
    editingItem = null;
    editingIdx = -1;
    availablePages = [];

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined) {
            this.sectionValue = this.defaultValues();
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    constructor(private sectionsService: ThemeEditorSectionConfigService) {}

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        this.availablePages = this.sectionsService.getAvailablePages();
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onEditMenuItem(item, idx, ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.editingItem = Object.assign({}, item);
        this.editingIdx = idx;
    }

    onCancelEditItem() {
        this.editingItem = null;
        this.editingIdx = -1;
    }

    onUpdateItem() {
        if (this.editingIdx === -1) {
            this.onCancelEditItem();
            return;
        }
        this.sectionValue.menu[this.editingIdx] = this.editingItem;
        this.onSectionChange();
        this.onCancelEditItem();
    }

    onAddItem() {
        const newItem = {
            title: 'Nueva Sección',
            uri: ''
        };
        this.sectionValue.menu.push(newItem);
        this.onEditMenuItem(newItem, this.sectionValue.menu.length - 1, null);
    }

    onDeleteItem() {
        swal({
            title: '¿Estás seguro de que quieres eliminar el elemento?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimínalo.',
            cancelButtonText: 'Cancelar'
        }).then((confirm) => {
            if (!confirm.value) {
                return;
            }
            this.sectionValue.menu.splice(this.editingIdx, 1);
            this.onSectionChange();
            this.onCancelEditItem();
        });
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    defaultValues() {
        return {
            alignment: 'left',
            logo: '',
            logoWidth: '100px',
            ad: {
                show: false,
                onlyHome: true,
                text: 'Escribe tu anuncio',
                link: '',
                background: '#3855d8',
                color: '#fff'
            }
        };
    }
}
