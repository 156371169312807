import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
    selector: 'mvta-element-title',
    templateUrl: 'block-title.component.html'
})
export class ThemeEditorBlockTitleConfigComponent implements OnInit {
    ckEditorConfig = {
        removeButtons: 'Source,Save,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,CopyFormatting,RemoveFormat,NumberedList,Outdent,' +
        'Blockquote,BidiLtr,Image,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Format,Font,FontSize,NewPage,' +
        'Preview,Print,PasteText,Paste,Copy,Replace,Redo,PasteFromWord,Checkbox,Radio,TextField,Textarea,Select,Button,' +
        'ImageButton,HiddenField,BulletedList,Indent,CreateDiv,BidiRtl,Anchor,' +
        'Language,Flash,Table,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe,Subscript,Superscript,Strike'
    };
    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    constructor(private sectionService: ThemeEditorSectionConfigService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onFileImageChange(file) {
        console.log('file change', file);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    defaultValues() {
        return {
            type: 'block_text',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: {
                title: 'Título',
                titleAlignment: 'left',
                html: 'Contenido de tu <strong>sección</strong>',
            }
        };
    }
}
