import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SECTION_TYPE_LIST } from '../section.list';

@Component({
    selector: 'mvta-theme-editor-add-section',
    templateUrl: 'add.component.html'
})
export class ThemeEditorAddSectionConfigComponent {

    sectionList = [];
    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined) {
            this.sectionValue = {};
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    constructor() {
        this.sectionList = Object.keys(SECTION_TYPE_LIST)
            .map((k) => {
                const r = SECTION_TYPE_LIST[k];
                r.type = k;
                return r;
            })
            .filter((s) => !s.fixed);
    }

    onSelectSection(section) {
        this.sectionValue = {
            type: section.type,
            isNew: true,
            isTop: this.sectionValue.isTop,
        };
        this.sectionChange.emit(this.sectionValue);
        setTimeout(() => this.onGoBack(null), 1);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }
}
