import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/auth';
import { PageImplOrder } from '../order.interface';
import { Package } from './order.interface';

@Injectable()
export class OrdersService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  get(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/order/${id}`);
  }

  filter(
    status = '',
    type = '',
    from = '',
    shippingStatus = '',
    deliveryDate = '',
    term = ''
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        status: status.length > 0 ? status : 'all',
        type: type.length > 0 ? type : 'all',
        fromDate: from.length > 0 ? from : 'all',
        shippingStatus: shippingStatus.length > 0 ? shippingStatus : 'all',
        deliveryDate,
        page: '0',
        rows_per_page: '0',
        term,
      }
    });

    return this.http.get(`${environment.apiUrl}/api/v1/admin/orders`, {
      params: qParams,
    });
  }


  filterWithPagination(
    status = '',
    type = '',
    creationDateFrom = '',
    shippingStatus = '',
    deliveryDate = '',
    term = '',
    creationDateTo = '',
    deliveryDateTo = '',
    paidAtFrom = '',
    paidAtTo = '',
    page = '0',
    sort = [],
    size = '0',
  ): Observable<PageImplOrder> {
    const qParams = new HttpParams({
      fromObject: {
        status: status.length > 0 ? status : 'all',
        type: type.length > 0 ? type : 'all',
        fromDate: creationDateFrom.length > 0 ? creationDateFrom : 'all',
        toDate: creationDateTo.length > 0 ? creationDateTo : '',
        shippingStatus: shippingStatus.length > 0 ? shippingStatus : 'all',
        deliveryDate,
        deliveryDateTo,
        paidAtFrom,
        paidAtTo,
        page,
        sort,
        size,
        term,
      }
    });

    return this.http.get<PageImplOrder>(`${environment.apiUrl}/api/v2/admin/orders`, {
      params: qParams,
    });
  }

  updatePackage(pkg: Package, notify: boolean = true, isLocal: boolean = false): Observable<any> {
    delete pkg['updatedAt'];
    delete pkg['createdAt'];
    const qParams = new HttpParams({
      fromObject: {
        notify: String(notify),
        local: String(isLocal)
      }
    });

    return this.http.put(`${environment.apiUrl}/api/v1/admin/package`, pkg, { params: qParams });
  }

  updateStatus(id: string, status: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/order', { orderId: id, status: status });
  }

  markAsPaid(id: string, reason: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/order/paid', { orderId: id, reason: reason });
  }

  searchOrders(term, page, rowsPerPAge): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        status: 'all',
        term: term
      }
    });
    return this.http.get(environment.apiUrl + `/api/v1/admin/orders/search`, { params: qParams });
  }

  getProductsDetails(skus): Observable<any> {
    return this.http.post(environment.apiUrl + `/api/v1/admin/store/products-info`, skus)
  }

  getDashboardInfo(): Observable<any> {
    return this.http.get(environment.apiUrl + `/api/v1/admin/dashboard`)
  }

  generateAuthCode(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/generate-auth-code`, data);
  }

  refundItems(orderId: string, refundInput): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/order/${orderId}/refund`, refundInput);
  }

  refundTransactions(orderId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/order/${orderId}/refunds`);
  }
}
