import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { BreadcrumbsComponent } from './layouts/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layouts/admin/title/title.component';
import { CleanLayoutComponent } from './layouts/clean/clean-layout.component';
import { OrderPrintComponent } from './logistics/orders/print/print.component';
import { PaymentsListComponent } from './payments/list/list.component';
import { PaymentsMakeComponent } from './payments/make/make.component';
import { Angulartics2KeenIO } from './shared/analytics/angulartics2-keenio';
import { AuthGuard, AuthService, FeaturesGuard } from './shared/auth';
import { DuePaymentService } from './shared/due-payment/due-payment.service';
import { DuePaymentState } from './shared/due-payment/due-payment.state';
import { SharedModule } from './shared/shared.module';
import { TemplatePreviewComponent } from './template-preview/template-preview.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    CleanLayoutComponent,
    BreadcrumbsComponent,
    TitleComponent,
    PaymentsMakeComponent,
    PaymentsListComponent,
    OrderPrintComponent,
    TemplatePreviewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    Angulartics2Module.forRoot([Angulartics2KeenIO]),
    ToastrModule.forRoot({ closeButton: true }),
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  exports: [],
  providers: [
    AuthService,
    AuthGuard,
    FeaturesGuard,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DuePaymentState,
    DuePaymentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
