import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mvta-input-edit-text',
  templateUrl: 'custom-input-edit-text.component.html',
  styles: [':host {display: block; width: 100%;}'],
  styleUrls: ['custom-input-edit-text.component.scss']
})

export class InputEditComponent implements OnInit, OnChanges {
  uniqueId;
  defaultPlaceholder = '[ Ingresar texto ]';
  @ViewChild('inputContent') inputContent: ElementRef;

  @Input() content;
  @Output() contentChange = new EventEmitter<string>();

  @Input() editMode = false;
  @Output() onChangeEditMode = new EventEmitter<boolean>();
  @Output() onEditingEnded = new EventEmitter();

  @Input() placeholder: string | null = null;
  @Input() disabled = false;

  constructor() {
    this.placeholder = this.defaultPlaceholder;
    this.uniqueId = Math.random().toString(36).substring(2);
  }

  ngOnInit() { }

  ngOnChanges() {
    if (this.placeholder === null || this.placeholder.length === 0) {
      this.placeholder = this.defaultPlaceholder;
    }
  }

  onContentChange(ev) {
    this.content = ev;
    this.contentChange.emit(this.content);
  }

  onEditMode(ev) {
    if (this.disabled) {
      return;
    }
    this.preventPropagation(ev);

    this.editMode = true;
    this.onChangeEditMode.emit(this.editMode)
    setTimeout(() => {
      this.inputContent.nativeElement.focus();
    }, 500);
  }

  preventPropagation(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  onEditBlur() {
    this.emitChanges();
  }

  onKeyUp(event) {
    if (event.key === 'Enter') {
      this.emitChanges();
      return;
    }
    if (event.keyCode === 27) {
      this.emitChanges();
      return;
    }
    this.preventPropagation(event);
    this.contentChange.emit(this.content);
  }

  emitChanges() {
    this.editMode = false;
    this.onChangeEditMode.emit(this.editMode)
    this.onEditingEnded.emit();
  }
}
