import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { FONT_GROUPS, FONT_LIST } from '../../shared/elements/font-list';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
  selector: 'mvta-element-banner-title',
  templateUrl: 'banner-title.component.html'
})
export class ThemeEditorBannerRectangularComponent implements OnInit {

  sectionValue;
  debouncedSectionChange;

  @Input()
  get section() {
    return this.sectionValue;
  }

  set section(val) {
    if (val === null || val === undefined || val.isNew) {
      this.sectionValue = this.defaultValues();
      this.sectionValue.isTop = val.isTop || false;
      return;
    }
    this.sectionValue = val;
    if (this.sectionValue.images.length === 0) {
      this.sectionValue.images.push({
        image: '',
        title1: '',
        title2: '',
      });
    }
  }

  @Output()
  goBack = new EventEmitter();

  @Output()
  sectionChange = new EventEmitter();

  @Output()
  sectionDelete = new EventEmitter();

  constructor(private sectionService: ThemeEditorSectionConfigService) {
  }

  ngOnInit() {
    this.debouncedSectionChange = debounce(() => {
      this.sectionChange.emit(this.sectionValue);
    }, 800);
    if (this.sectionValue.isNew) {
      this.sectionChange.emit(this.sectionValue);
    }
  }

  onSectionChange() {
    this.debouncedSectionChange();
  }

  onGoBack(ev) {
    this.goBack.emit(ev);
  }

  onDeleteSection() {
    this.sectionDelete.emit(this.sectionValue);
  }

  defaultValues() {
    return {
      type: 'banner-title',
      isNew: true,
      id: this.sectionService.getRandomId(),
      settings: {

      }
    };
  }
}
