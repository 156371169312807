import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';


@Component({
  selector: 'mvta-content-page-container',
  templateUrl: 'content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
})
export class ContentPageComponent implements OnInit {


  constructor( ){}

  ngOnInit() {
  }

}
