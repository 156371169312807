import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Angulartics2 } from 'angulartics2';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';

@Injectable()
export class FeaturesGuard implements CanActivate {
  private jwtHelper: JwtHelperService;

  constructor(private router: Router, private angulartics2: Angulartics2) {
    this.jwtHelper = new JwtHelperService();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const accountToken: string = sessionStorage.getItem(environment.accessToken) || '';
    const tokenDetails = this.jwtHelper.decodeToken(accountToken) || {};
    if (!route.data || !route.data.hasOwnProperty('require')) {
      console.warn('A route is configured to use scope auth, but no provides "require" data route field')
      this.angulartics2.eventTrack.next({
        action: 'scopeGuard',
        properties: {
          developerMessage: 'Invalid scope guard config (does not have "requirements" field)',
          action: 'invalidScopeConfig',
          url: route.url
        }
      });
      return true;
    }

    // Set requirements as array
    const requirements = (typeof route.data.require === 'string') ? [route.data.require] : route.data.require;
    const section = route.data.section || '';

    // Get restrictions
    const restrictions = (tokenDetails.restrictions.split(' ') || [])
      .map((f) => f.trim())
      .filter((s) => s && s.length > 0);

    // Get plan & features for account (feature_name:feat_val)
    const features = (tokenDetails.scope.split(' ') || [])
      .map((f) => f.split(':')[0].trim())
      .filter((s) => s && s.length > 0 && !restrictions.some((r) => r === s));
    if (tokenDetails.plan && tokenDetails.plan.length > 1 && tokenDetails.plan !== 'na') {
      features.push('hasPlan');
    }

    // Return false if some of the requirements is not found in scopes
    const meetsRequirements = !(requirements.some((r) => !features.some((c) => r === c)));
    const hasRestriction = requirements.some((r) => restrictions.some((re) => r === re));
    const sectionRestricted = restrictions.some((r) => r === section);
    if (!meetsRequirements || hasRestriction || sectionRestricted) {
      let errorMessage = 'Esta sección no está habilitada para tu cuenta. Revisa las opciones de tu plan';
      let redirectTo = ['/cuenta/planes-pagos/resumen'];
      if (!features.some((s) => s === 'hasPlan')) {
        errorMessage = 'No has seleccionado ningún plan para tu cuenta. Por favor revisa las opciones y selecciona un plan.';
        redirectTo = ['/cuenta/planes-pagos/planes'];
      }

      swal({
        type: 'warning',
        title: 'Sin Acceso',
        text: errorMessage
      });
      this.router.navigate(redirectTo);
      this.angulartics2.eventTrack.next({
        action: 'scopeGuard',
        properties: {
          developerMessage: 'User does not has access to a route',
          action: 'noRouteAccess',
          scope: features,
          requirements: requirements,
          url: route.url
        }
      });
    }

    return meetsRequirements;
  }
}
