import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mvta-title',
  template: ''
})
export class TitleComponent {
  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let currentRoute = this.route.root;
        let title = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach((childRoute) => {
            if (childRoute.outlet === 'primary') {
              title = childRoute.snapshot.data.breadcrumb || '';
              currentRoute = childRoute;
            }
          })
        } while (currentRoute);
        this.titleService.setTitle(`${title.length > 0 ? title + ' | ' : ''} Sheyla.mx`);
      });
  }
}
