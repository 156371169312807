export const SECTION_TYPE_LIST = {
    welcomeArea: {
        name: {
            es: 'Bienvenida',
            en: 'Welcome',
        },
        icon: 'ti-layout-slider-alt'
    },
    banner: {
        name: {
            es: 'Banner',
            en: 'Banner',
        },
        icon: 'ti-blackboard',
    },
    pageBanner: {
        name: {
            es: 'Banner de página',
            en: 'Page Banner',
        },
        icon: 'ti-layout-media-center',
    },
    itemsCategory: {
        name: {
            es: 'Categoría',
            en: 'Items category',
        },
        icon: 'ti-tag',
    },
    image: {
        name: {
            es: 'Imagen',
            en: 'Image',
        },
        icon: 'ti-image',
    },
    blockText: {
        name: {
            es: 'Bloque de Texto',
            en: 'Text block',
        },
        icon: 'ti-paragraph',
    },
    quote: {
        name: {
            es: 'Cita',
            en: 'Quote',
        },
        icon: 'ti-quote-right',
    },
    contactInfo: {
        name: {
            es: 'Información de Contacto',
            en: 'Contact Information',
        },
        icon: 'ti-book',
        fixed: true,
    },
    header: {
        name: {
            es: 'Encabezado',
            en: 'Header',
        },
        icon: 'ti-layout-tab-window',
        fixed: true,
    },
    footer: {
        name: {
            es: 'Pie de página',
            en: 'Footer',
        },
        icon: 'ti-layout-accordion-merged',
        fixed: true,
    },
};
