import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { AccountService } from '../auth';
import { PaymentInput } from '../credit-card-manager/payment-input.interface';
import { DuePaymentService } from './due-payment.service';
import { DuePaymentState } from './due-payment.state';

@Component({
  selector: 'mvta-due-payment-modal',
  templateUrl: './due-payment.component.html'
})
export class DuePaymentModalComponent implements OnInit, OnDestroy {

  dueAndCycle;
  duePaymentWatcher;
  paying = false;

  constructor(private duePaymentState: DuePaymentState, private dueService: DuePaymentService,
    private toastrService: ToastrService, private accountService: AccountService) {
    this.dueAndCycle = {};
  }

  ngOnInit() {
    this.duePaymentWatcher = this.duePaymentState.dueChange
      .subscribe((dueAndCycle) => {
        this.dueAndCycle = dueAndCycle;
      });
  }

  ngOnDestroy() {
    this.duePaymentWatcher.unsubscribe();
  }

  onCardSelected(card) {
    const payment: PaymentInput = {
      paymentMethodId: card.id,
      cvv2: card.ccCvv2,
      deviceId: card.deviceId,
      payerName: '',
      payerEmail: ''
    };
    this.paying = true;
    this.dueService.duePayment(payment)
      .subscribe((response) => {
        this.paying = false;
        const paid = response.paymentResponse.paid || false;
        if (paid) {
          this.accountService.save(response.jwt);
          swal({
            type: 'success',
            title: 'Gracias por tu Pago',
            text: 'Se ha realizado exitosamente el cobro.'
          }).then(() => {
            window.location.href = '/';
          });
          return;
        }
        // Can not paid, get details
        const gatewayResponse = this.parseGatewayResponse(response.paymentResponse.gatewayResponse || '{}');
        const description = gatewayResponse['description'] || 'No hay detalles adicionales';
        swal({
          title: 'No se ha realizado el cobro.',
          html: `Lo sentimos, no hemos podido procesar el cobro. <br> El procesador de pagos ha dicho:<br><br><i>${description}</i>`,
          type: 'warning'
        });
      }, () => {
        swal({
          title: 'No se ha realizado el cobro.',
          html: `Lo sentimos, no hemos podido realizar el cobro.`,
          type: 'warning'
        });
        this.paying = false;
      });
  }

  parseGatewayResponse(str: string) {
    let ret = {};
    try {
      ret = JSON.parse(str);
    } catch (e) {
      ret = false;
    }
    return ret;
  }
}
