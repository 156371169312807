import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[gravatar]'
})
export class GravatarDirective implements OnInit, OnChanges {

    @Input('email') email: string;
    @Input('size') size = 16;
    @Input('fallback') fallback = 'identicon';

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        this.setUrlGravatar(this.email);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['email']) {
            this.setUrlGravatar(changes['email'].currentValue);
        }
    }

    setUrlGravatar(email: string) {
        if (!email || email.length === 0) {
            return;
        }
        const hash = Md5.hashStr(email.trim().toLowerCase());
        this.elementRef.nativeElement.src = `//www.gravatar.com/avatar/${hash}?s=${this.size}&d=${this.fallback}`;
    }
}
