import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ORDER_STATUS } from '../../order.interface';
import { OrderDetails } from './order.interface';

@Component({
  selector: 'mvta-order-payment-details',
  templateUrl: './order-payment-details.component.html',
  styleUrls: ['./order-payment-details.component.scss']
})
export class OrderPaymentDetailsComponent implements OnChanges {
  ORDER_STATUS = ORDER_STATUS;
  CLEARSALE_STATUS = {
    APA: 'Automatic Approval',
    APM: 'Manual Approval',
    RPM: 'Denied without Prejudice',
    AMA: 'Manual Analysis',
    ERR: 'Error',
    NVO: 'New Order',
    SUS: 'Fraud Suspicion',
    CAN: 'Customer Asked for Cancellatio',
    FRD: 'Confirmed Fraud',
    RPA: 'Automatically Denied',
    RPP: 'Denied by Policy'
  };

  @Input() order: OrderDetails;

  constructor() { }

  ngOnChanges() { }
}
