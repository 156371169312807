import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ThemeEditorService } from '../theme-editor.service';

@Injectable()
export class ThemeEditorSectionConfigService {

    private reservedNames = ['settings', 'cart'];
    private description;
    private layouts = [];

    constructor(private themeEditorService: ThemeEditorService) {
        this.description = {};
    }

    setDescription(description) {
        this.description = Object.assign({}, description);
    }

    getDescription() {
        return this.description;
    }

    getAvailablePages(): Array<any> {
        if (!this.description || !this.description.pages) {
            return [];
        }

        return Object.keys(this.description.pages)
            .map((k) => this.description.pages[k])
            .map((page) => {
                return {
                    title: page.name,
                    uri: page.slug
                };
            });
    }

    getLayouts(forceReload = false) {
        if (!forceReload && this.layouts.length > 0) {
            return of(this.layouts);
        }
        return Observable.create((observer) => {
            this.themeEditorService.getLayouts()
                .subscribe((response) => {
                    this.layouts = response || [];
                    this.layouts = this.layouts
                        .filter((l) => !this.reservedNames.some((r) => r === l.name))
                        .map((l) => this.parseLayoutSettings(l));
                    observer.next(this.layouts);
                    observer.complete();
                }, () => {
                    this.layouts = [];
                    observer.next(this.layouts);
                    observer.complete();
                });
        });
    }

    getRandomId() {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    }

    parseLayoutSettings(layout) {
        try {
            layout.settingsObj = JSON.parse(layout.settings);
        } catch (e) {
            layout.settingsObj = {};
        }

        return layout;
    }
}
