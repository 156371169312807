import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AccountService {
  private jwtHelper: JwtHelperService;

  public tokenChange: BehaviorSubject<string>;

  constructor() {
    this.jwtHelper = new JwtHelperService();
    this.tokenChange = new BehaviorSubject<string>(this.getToken());
  }

  save(token) {
    sessionStorage.setItem(environment.accessToken, token);
    this.tokenChange.next(token);
  }

  saveAccount(accountId) {
    sessionStorage.setItem(environment.accountKey, accountId);
  }

  clean() {
    sessionStorage.removeItem(environment.accessToken);
    this.tokenChange.next(null);
  }

  hasAccountToken(acc): boolean {
    const audience = this.tokenDetails().aud || '';
    return audience.toLowerCase() === acc.toLowerCase();
  }

  hasAccount(): boolean {
    return !!this.getToken();
  }

  tokenDetails(): any {
    if (!this.getToken() || this.jwtHelper.isTokenExpired(this.getToken())) {
      this.clean();
      return {};
    }

    return this.jwtHelper.decodeToken(this.getToken());
  }

  hasTransactionFee(): boolean {
    return this.hasFeature('transaction_fee');
  }

  hasFeature(feature: string): boolean {
    const details = this.tokenDetails();
    if (Object.keys(details).length === 0) {
      return false;
    }
    const features = details.scope.split(' ').map((f) => {
      const parts = f.trim().split(':');
      if (parts.length !== 2) {
        return {name: parts[0], value: 0};
      }
      return {name: parts[0], value: parts[1]};
    });

    return (features.find((f) => f.name === feature) !== undefined);
  }

  hasPlan(): boolean {
    const details = this.tokenDetails();
    if (Object.keys(details).length === 0) {
      return false;
    }

    return (details.plan && details.plan.length > 1 && details.plan !== 'na');
  }

  getToken(): any {
    return sessionStorage.getItem(environment.accessToken) || false;
  }

  getAccountKey() {
    return sessionStorage.getItem(environment.accountKey) || '';
  }

  removeAccountKey() {
    sessionStorage.removeItem(environment.accountKey);
  }
}
