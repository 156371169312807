import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';

@Component({
    selector: 'mvta-theme-editor-contact-info',
    templateUrl: 'contact-info.component.html'
})
export class ThemeEditorContactInfoConfigComponent implements OnInit {
    ckEditorConfig = {
        removeButtons: 'Source,Save,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,CopyFormatting,RemoveFormat,NumberedList,Outdent,' +
        'Blockquote,BidiLtr,Image,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Format,Font,FontSize,NewPage,' +
        'Preview,Print,PasteText,Paste,Copy,Replace,Redo,PasteFromWord,Checkbox,Radio,TextField,Textarea,Select,Button,' +
        'ImageButton,HiddenField,BulletedList,Indent,CreateDiv,BidiRtl,Anchor,' +
        'Language,Flash,Table,HorizontalRule,SpecialChar,Smiley,PageBreak,Iframe,Subscript,Superscript,Strike'
    };

    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined) {
            this.sectionValue = this.defaultValues();
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    defaultValues() {
        return {
            title: 'Contáctanos',
            html: 'Información o descripción acerca de tu negocio',
            address: 'Paseo Opera 20, Puebla, MX',
            phone: '+52 222 12 34 567',
            email: 'contacto@website'
        };
    }
}
