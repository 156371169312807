import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
    selector: 'mvta-theme-editor-template-color',
    templateUrl: 'template-color.component.html',
    styleUrls: ['./template-color.component.scss']
})
export class ThemeEditorTemplateColorConfigComponent implements OnInit {

    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    colors = [
        {
          name: 'custom',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(145, 44, 212)',
          mvta_header_gradient_end: 'rgb(164, 127, 175)',
          mvta_carrousel_init: 'rgb(145, 44, 212)',
          mvta_carrousel_text_init: '#fff',
          mvta_footer_top_init: 'rgb(224, 181, 224)',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgb(233, 209, 235)',
          mvta_button_color: 'rgb(78, 56, 76)',
          mvta_button_hover_bg: '#886388',
          mvta_button_hover_border: 'rgb(114, 84, 117)',
          mvta_h2_init: 'rgb(166, 126, 164)',
          mvta_h2_font: '"Raleway", sans-serif',
          mvta_link_init: '#000',
          mvta_p_init: 'rgb(126, 94, 124)',
          mvta_font_init: '"Raleway", sans-serif',
          mvta_contact_title_init: 'rgb(233, 179, 226)',
          mvta_contact_font_init: '"Raleway", sans-serif',
          mvta_contact_send_button_init: 'rgb(147, 110, 141)',
          mvta_contact_send_button_text_init: 'rgb(248, 232, 247)',
          mvta_cart_button_bg: 'rgb(233, 209, 235)',
          mvta_cart_button_color: 'rgb(78, 56, 76)',
          mvta_cart_button_hover_bg: '#886388',
          mvta_cart_button_hover_border: 'rgb(114, 84, 117)'
        },
        {
          name: 'green',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(70, 97, 92)',
          mvta_header_gradient_end: 'rgb(127, 175, 166)',
          mvta_carrousel_init: 'rgb(70, 97, 92)',
          mvta_carrousel_text_init: '#fff',
          mvta_footer_top_init: 'rgb(179, 223, 215)',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgb(209, 235, 230)',
          mvta_button_color: 'rgb(56, 78, 74)',
          mvta_button_hover_bg: 'rgb(99, 136, 129)',
          mvta_button_hover_border: 'rgb(84, 117, 111)',
          mvta_h2_init: 'rgb(111, 157, 165)',
          mvta_h2_font: '"Raleway", sans-serif',
          mvta_link_init: '#000',
          mvta_p_init: 'rgb(93, 121, 126)',
          mvta_font_init: '"Raleway", sans-serif',
          mvta_contact_title_init: 'rgb(179, 224, 233)',
          mvta_contact_font_init: '"Raleway", sans-serif',
          mvta_contact_send_button_init: 'rgb(110, 141, 147)',
          mvta_contact_send_button_text_init: 'rgb(232, 245, 248)',
          mvta_cart_button_bg: 'rgb(209, 235, 230)',
          mvta_cart_button_color: 'rgb(56, 78, 74)',
          mvta_cart_button_hover_bg: 'rgb(99, 136, 129)',
          mvta_cart_button_hover_border: 'rgb(84, 117, 111)'
        },
        {
          name: 'blue',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(70, 72, 97)',
          mvta_header_gradient_end: 'rgb(128, 127, 175)',
          mvta_footer_top_init: 'rgb(181, 191, 224)',
          mvta_carrousel_init: 'rgb(70, 72, 97)',
          mvta_carrousel_text_init: '#fff',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgb(209, 212, 235)',
          mvta_button_color: 'rgb(56, 58, 78)',
          mvta_button_hover_bg: '#636788',
          mvta_button_hover_border: 'rgb(86, 84, 117)',
          mvta_h2_init: 'rgb(126, 132, 166)',
          mvta_h2_font: '"Raleway", sans-serif',
          mvta_link_init: '#000',
          mvta_p_init: 'rgb(95, 94, 126)',
          mvta_font_init: '"Raleway", sans-serif',
          mvta_contact_title_init: 'rgb(180, 179, 233)',
          mvta_contact_font_init: '"Raleway", sans-serif',
          mvta_contact_send_button_init: 'rgb(110, 110, 147)',
          mvta_contact_send_button_text_init: 'rgb(232, 233, 248)',
          mvta_cart_button_bg: 'rgb(209, 212, 235)',
          mvta_cart_button_color: 'rgb(56, 58, 78)',
          mvta_cart_button_hover_bg: '#636788',
          mvta_cart_button_hover_border: 'rgb(86, 84, 117)'
        },
        {
          name: 'red',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(97, 70, 78)',
          mvta_header_gradient_end: 'rgb(175, 127, 141)',
          mvta_carrousel_init: 'rgb(97, 70, 78)',
          mvta_carrousel_text_init: '#fff',
          mvta_footer_top_init: 'rgb(224, 181, 195)',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgb(235, 209, 215)',
          mvta_button_color: 'rgb(78, 56, 64)',
          mvta_button_hover_bg: '#88636c',
          mvta_button_hover_border: 'rgb(117, 84, 95)',
          mvta_h2_init: 'rgb(166, 126, 136)',
          mvta_h2_font: '"Raleway", sans-serif',
          mvta_link_init: '#000',
          mvta_p_init: 'rgb(126, 94, 104)',
          mvta_font_init: '"Raleway", sans-serif',
          mvta_contact_title_init: 'rgb(233, 179, 197)',
          mvta_contact_font_init: '"Raleway", sans-serif',
          mvta_contact_send_button_init: 'rgb(147, 110, 118)',
          mvta_contact_send_button_text_init: 'rgb(248, 232, 237)',
          mvta_cart_button_bg: 'rgb(235, 209, 215)',
          mvta_cart_button_color: 'rgb(78, 56, 64)',
          mvta_cart_button_hover_bg: '#88636c',
          mvta_cart_button_hover_border: 'rgb(117, 84, 95)'
        },
        {
          name: 'yellow',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(97, 96, 70)',
          mvta_header_gradient_end: 'rgb(175, 172, 127)',
          mvta_carrousel_init: 'rgb(97, 96, 70)',
          mvta_carrousel_text_init: '#fff',
          mvta_footer_top_init: 'rgb(223, 224, 181)',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgb(233, 235, 209)',
          mvta_button_color: 'rgb(73, 78, 56)',
          mvta_button_hover_bg: '#858863',
          mvta_button_hover_border: 'rgb(112, 117, 84)',
          mvta_h2_init: 'rgb(162, 166, 126)',
          mvta_h2_font: '"Raleway", sans-serif',
          mvta_link_init: '#000',
          mvta_p_init: 'rgb(120, 126, 94)',
          mvta_font_init: '"Raleway", sans-serif',
          mvta_contact_title_init: 'rgb(229, 233, 179)',
          mvta_contact_font_init: '"Raleway", sans-serif',
          mvta_contact_send_button_init: 'rgb(147, 146, 110)',
          mvta_contact_send_button_text_init: 'rgb(248, 248, 232)',
          mvta_cart_button_bg: 'rgb(233, 235, 209)',
          mvta_cart_button_color: 'rgb(73, 78, 56)',
          mvta_cart_button_hover_bg: '#858863',
          mvta_cart_button_hover_border: 'rgb(112, 117, 84)'
        },
        {
          name: 'abros',
          mvta_header_init: 'lavender',
          mvta_header_gradient_init: 'rgb(250, 250, 250)',
          mvta_header_gradient_end: 'rgb(250,250,250)',
          mvta_carrousel_init: '#414141',
          mvta_carrousel_text_init: '#fff',
          mvta_carousel_title_slide_color: '#414141',
          mvta_carousel_subtitle_color: '#414141',
          mvta_carousel_subtitle_slide_font_family: 'Playfair Display',
          mvta_footer_top_init: '#222222',
          mvta_footer_bottom_init: '#222222',
          mvta_button_bg: 'rgba(74, 20, 140,1)',
          mvta_button_color: ' rgb(250,250,250)',
          mvta_button_hover_bg: 'rgb(250,250,250)',
          mvta_button_hover_border: 'rgb(74, 20, 140)',
          mvta_h1_init: '#000',
          mvta_h1_font: 'Poppins,',
          mvta_h2_init: '#000',
          mvta_h2_font: '"PlayfairDisplay-Bold"',
          mvta_link_init: 'rgb(178,178,178)',
          mvta_link_hover: 'rgba(142,36,170,1)',
          mvta_p_init: 'rgb(178,178,178)',
          mvta_font_init: '"Poppins"',
          mvta_contact_font_init: '"Dancing Script"',
          mvta_contact_send_button_init: 'rgb(74, 20, 140)',
          mvta_contact_send_button_text_init: 'rgb(248, 232, 237)',
          mvta_cart_button_bg: 'rgb(235, 209, 215)',
          mvta_cart_button_hover_bg: 'rgba(142,36,170)',
        }
      ];

    constructor(private sectionService: ThemeEditorSectionConfigService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    onAmarillo() {
        this.sectionValue.settings.colors = this.colors.find((a) => a.name === 'yellow')
        this.onSectionChange();
    }
    onRojo() {
        this.sectionValue.settings.colors = this.colors.find((a) => a.name === 'red')
        this.onSectionChange();
    }
    onAzul() {
        this.sectionValue.settings.colors = this.colors.find((a) => a.name === 'blue')
        this.onSectionChange();
    }
    onVerde() {
        this.sectionValue.settings.colors = this.colors.find((a) => a.name === 'green')
        this.onSectionChange();
    }
    onAbrosColors() {
      this.sectionValue.settings.colors = this.colors.find((a) => a.name === 'abros')
      this.onSectionChange();
  }

    defaultValues() {
        return {
            type: 'template_color',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: { templatevars: {}, },
        };
    }
}
