import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Directive({ selector: '[mvtaHoldable]' })
export class HoldableDirective {
  @Output() holdtime: EventEmitter<number> = new EventEmitter();

  INTERVAL_PERIOD = 100;
  state: Subject<string> = new Subject();
  cancel: Observable<string>;

  constructor() {
    this.cancel = this.state.pipe(
      filter(s => s === 'cancel'),
      tap(() => this.holdtime.emit(0))
    );
  }

  @HostListener('mouseup', ['$event'])
  // @HostListener('mouseleave', ['$event'])
  onExit() {
    this.state.next('cancel');
  }

  @HostListener('mousedown', ['$event'])
  onHold() {
    this.state.next('start');

    interval(this.INTERVAL_PERIOD).pipe(
      takeUntil(this.cancel),
      tap(milliseconds => this.holdtime.emit(milliseconds * this.INTERVAL_PERIOD))
    ).subscribe()
  }

}
