import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayIndexes'
})

export class ArrayIndexesPipe implements PipeTransform {
  transform(array: any[], startAt: number, jump?: number): number[] {
    const indexes = array.map((_, index) => index).slice(startAt);

    if (!jump) {
      return indexes;
    }

    return indexes.filter((_, index) => index % jump === 0);
  }
}
