import { OnChanges, OnDestroy, Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';

@Component({
  selector: 'mvta-theme-editor-itemsnxrow',
  templateUrl: './itemsnxrow.component.html',
  styleUrls: ['./itemsnxrow.component.scss']
})
export class ThemeEditorItemsNxRowConfigComponent implements OnInit, OnChanges, OnDestroy {

  sectionValue;
  debouncedSectionChange;

  changesObserver;

  @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

  constructor() { }

  ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

  ngOnChanges() {
     if (this.sectionValue !== undefined && this.sectionValue !== null) {
      this.emitChanges();
    }
  }

  onAssignCheckedPCTablet(itemsPerRowPCTablet) {
    if (this.sectionValue.settings.itemsPerRowPCTablet === itemsPerRowPCTablet) {
      this.emitChanges();
      return true;
    }
  }

  onAssignCheckedMobile(itemsPerRowMobile) {
    if (this.sectionValue.settings.itemsPerRowMobile === itemsPerRowMobile) {
      this.emitChanges();
      return true;
    }
  }

  onSelectPCTablet(itemsPerRowPCTablet) {
    this.sectionValue.settings.itemsPerRowPCTablet = itemsPerRowPCTablet;
  }

  onSelectMobile(itemsPerRowMobile) {
    this.sectionValue.settings.itemsPerRowMobile = itemsPerRowMobile;
  }

  ngOnDestroy() {
    this.changesObserver.unsubscribe();
  }

  emitChanges() {
    this.sectionChange.emit(this.sectionValue);
  }

  onGoBack(ev) {
    this.goBack.emit(ev);
}

  defaultValues() {
  return {
      type: 'itemsnxrow',
      isNew: true,
      /* id: this.sectionService.getRandomId(), */
      settings: {
        itemsPerRowPCTablet: 4,
        itemsPerRowMobile: 4
      }
    };
  }
}
