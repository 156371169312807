import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import swal from 'sweetalert2';
import { Address } from '../interfaces/common.interface';
import { AddressesService } from './addresses.service';

@Component({
    selector: 'mvta-addresses',
    templateUrl: 'addresses.component.html',
    providers: [AddressesService]
})
export class AddressesComponent implements OnInit {

    loadingAddresses = false;
    loadingCountries = false;
    addressesValue: Array<Address>;
    addressValue: Address;
    addressIdValue: string;
    countries;
    regions;

    @Output()
    addressChange = new EventEmitter();

    @Input()
    get address() {
        return this.addressValue;
    }

    set address(val) {
        this.addressValue = val;
        this.addressChange.emit(this.addressValue);
        if (this.addressValue !== undefined && this.addressValue.country) {
            this.onCountryChange(this.addressValue.country);
        }
    }

    @Input()
    set addressId(val) {
        this.addressIdValue = val;
    }

    @Input()
    set addresses(val) {
        this.addressesValue = val || [];
        this.setAddress();
    }

    @Input()
    isFormSubmitted;

    constructor(private router: Router, private addressService: AddressesService,
        private angulartics2: Angulartics2) {
        this.countries = [];
        this.regions = [];
        this.addressesValue = [];
        this.addressValue = {};
        this.isFormSubmitted = false;
    }

    ngOnInit() {
        this.getShippingCountries();
    }

    setAddress() {
        if (Array.isArray(this.addressesValue) && this.addressesValue.length > 0) {
            if (this.addressIdValue !== undefined && this.addressIdValue.length > 1) {
                this.addressValue = this.addressesValue.find((a) => a.id === this.addressIdValue);
                if (!this.addressValue) {
                    this.addressValue = this.addressesValue[0];
                }
            } else {
                this.addressValue = this.addressesValue[0];
            }

            this.onCountryChange(this.addressValue.country);
            this.addressChange.emit(this.addressValue);
        } else {
            this.address.country = '';
            this.address.state = '';
        }
    }

    getShippingCountries() {
        this.loadingCountries = true;
        this.addressService.getShippingCountries()
            .subscribe((countries) => {
                this.countries = countries;
                this.onCountryChange(this.addressValue.country);
                this.angulartics2.eventTrack.next({
                    action: 'cart',
                    properties: {
                        developerMessage: 'Shipping countries retrieved',
                        action: 'shippingCountries'
                    }
                });
            }, (error) => {
                console.error('Can not get countries', error);
                swal({
                    title: 'Regiones de Envío',
                    text: 'No hay regiones de envío configuradas para ésta tienda. Contácte a soporte',
                    type: 'error'
                }).then(() => {
                    this.router.navigate(['/mensajes/no-regiones']);
                });
                this.angulartics2.eventTrack.next({
                    action: 'cart',
                    properties: {
                        developerMessage: 'Error retrieving shipping countries',
                        action: 'shippingCountriesError',
                        error: error
                    }
                });
            });
    }

    onAddressChange(ev) {
        this.addressValue = ev;
        this.onCountryChange(this.addressValue.country);
        this.addressChange.emit(this.addressValue);
        this.angulartics2.eventTrack.next({
            action: 'addresses',
            properties: {
                developerMessage: 'User change stored address',
                action: 'addressChange',
                address: ev
            }
        });
    }

    onCountryChange(ev) {
        this.angulartics2.eventTrack.next({
            action: 'addresses',
            properties: {
                developerMessage: 'User change country',
                action: 'countryChange',
                country: ev
            }
        });
        const country = this.countries.find((c) => c.code === ev);
        if (!country) {
            this.regions.length = 0;
            return;
        }
        this.regions = [].concat(country.regions);
        if (ev !== this.addressValue.country) {
            this.addressValue.state = '';
        }
    }
}
