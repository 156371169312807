import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';



import { AuthService } from '../shared/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PaymentsService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    getAll(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/admin/direct-payments');
    }

    makeDirectPayment(data): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/admin/direct-payment', data);
    }

    processDirectPayment(data): Observable<any> {
        return this.http.put(environment.apiUrl + '/api/v1/admin/direct-payment', data);
    }
}
