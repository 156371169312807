import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
    selector: 'mvta-theme-editor-image',
    templateUrl: 'image.component.html'
})
export class ThemeEditorImageConfigComponent implements OnInit {

    sectionValue;
    debouncedSectionChange;

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    constructor(private sectionService: ThemeEditorSectionConfigService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    defaultValues() {
        return {
            type: 'image',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: {
                image: '',
                title: 'Descripción',
            }
        };
    }
}
