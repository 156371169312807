import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';
import { ProductsService } from 'app/products/products.service';

@Component({
    selector: 'mvta-theme-editor-items-category',
    templateUrl: 'items-category.component.html',
    providers: [ProductsService]
})
export class ThemeEditorItemsCategoryConfigComponent implements OnInit {

    sectionValue;
    debouncedSectionChange;
    layouts = [];
    loadingLayouts = true;
    tags = [];

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined || val.isNew) {
            this.sectionValue = this.defaultValues();
            this.sectionValue.isTop = val.isTop || false;
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    @Output()
    sectionDelete = new EventEmitter();

    constructor(private sectionService: ThemeEditorSectionConfigService, private productsService: ProductsService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        if (this.sectionValue.isNew) {
            this.sectionChange.emit(this.sectionValue);
        }
        this.getProductCollections();

        if (this.sectionValue.settings.hasOwnProperty('tags')) {
            this.tags = this.sectionValue.settings.tags.list;
          }
    }

    getLayouts() {
        this.loadingLayouts = true;
        this.sectionService.getLayouts()
            .subscribe((response) => {
                this.layouts = response || [];
                this.loadingLayouts = false;
            });
    }

    onSectionChange() {
        if (this.sectionValue.settings.tags) {
            this.sectionValue.settings.tags.list = this.tags.map((t) => typeof t === 'object' ? t.value : t);
          }
        this.debouncedSectionChange();
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    onDeleteSection() {
        this.sectionDelete.emit(this.sectionValue);
    }

    defaultValues() {
        return {
            type: 'items_category',
            isNew: true,
            id: this.sectionService.getRandomId(),
            settings: {
                title: 'Artículos Destacados',
                category: 'inicio',
            },
        };
    }

    getProductCollections() {
        this.productsService.getCollections()
          .subscribe((r) => {
            this.layouts = r;
            this.loadingLayouts =  false;
          }, (e) => {
            console.error(e)
          })
      }
}
