import { Directive, ElementRef, Input, OnChanges, Renderer, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[mvtaFocus]'
})
export class FocusDirective implements OnChanges {

    @Input('mvtaFocus') isFocused: boolean;

    constructor(private hostElement: ElementRef, private renderer: Renderer) { }

    ngOnChanges(changes: SimpleChanges) {
        if (this.isFocused) {
            this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
            setTimeout(() => {
                this.hostElement.nativeElement.selectionStart = this.hostElement.nativeElement.selectionEnd = 10000;
            }, 0);
        }
    }
}
