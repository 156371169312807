import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class MvtaInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = sessionStorage.getItem(environment.accessToken) || false;
        // FIXME: Corregir problema con los objetos de fechas (createdAt, updatedAt) en la API y en los clientes
        // Por ahora, se revisa si la petición trae un cuerpo y es un objeto, en caso afirmativo se iteran sus atributos recursivamente
        // y se quitan los atributos de fecha, IMPORTANTE: no revisa ni itera los arrays de objetos
        if (req.body !== null && typeof req.body === 'object' && !Array.isArray(req.body) && !(req.body instanceof FormData)) {
          const newBody = this.removeDateAtProps(req.body);
          req = req.clone({body: newBody});
        }

        if (token || this.authService.isLoggedIn()) {
            let copyReq = req;
            if (token) {
                copyReq = req.clone({
                    headers: req.headers.append('X-Access-Token', token)
                });
            }

            if (this.authService.isLoggedIn()) {
                copyReq = copyReq.clone({
                    headers: copyReq.headers.append('Authorization', this.authService.tokenInHeader())
                });
            }

            return next.handle(copyReq).pipe(tap(this.handleResponse, this.handleError));
        }

        return next.handle(req).pipe(tap(this.handleResponse, this.handleError));
    }

    removeDateAtProps(obj) {
      const isObject = (o) => typeof o === 'object' && !Array.isArray(o) && o != null;
      const lookFor = ['createdAt', 'updatedAt'];
      const newObject = {};
      Object.keys(obj).forEach((k) => {
        if (lookFor.includes(k) && isObject(obj[k])) {
          return;
        }
        newObject[k] = obj[k];
        if (isObject(newObject[k])) {
          newObject[k] = this.removeDateAtProps(newObject[k]);
        }
      });
      return newObject;
    }

    handleResponse(response) {
        if (response instanceof HttpResponse) { }
    }

    handleError(responseError) {
        if (responseError instanceof HttpErrorResponse) {
            if (responseError.status === 401) {
                // Forbidden or Unauthorized
                sessionStorage.removeItem(environment.sessionToken);
                swal({
                    type: 'error',
                    title: 'Tu sesión ha expirado',
                    text: 'Vuelve a iniciar sesión'
                })
                .then(() => {
                    window.location.href = '/auth/login';
                });
            }

            if (responseError.status === 403) {
                if (window['mvta403'] !== 'true') {
                    window['mvta403'] = 'true';
                    const msg = responseError.error.msg || 'Ésta característica no está disponible para tu cuenta.';
                    swal({
                        type: 'error',
                        title: 'No Permitido',
                        text: msg
                    })
                    .then(() => {
                        window.location.href = '/';
                    });
                }
            }

            if (responseError.status === 402) {
                // Payment Required
                const sessionPayment = 'mvta402';
                let errorAlreadyShown = 'false';
                try {
                    errorAlreadyShown = sessionStorage.getItem(sessionPayment) || 'false';
                } catch (e) {}

                if (errorAlreadyShown === 'false') {
                    sessionStorage.setItem(sessionPayment, 'true');
                    swal({
                        type: 'warning',
                        title: 'Pago Pendiente',
                        html: 'Tu cuenta tiene un pago pendiente.' +
                            'Realiza el pago para poder continuar usando toda la funcionalidad del panel de administración.'
                    }).then(() => {
                        sessionStorage.removeItem(sessionPayment);
                        window.location.href = '/cuenta/planes-pagos/recibos';
                    });
                    return;
                }
            }

            if (responseError.status === 502) {
                // Bad Gateway
                const sessionBadGateway = 'mvta502';
                const maxRetries = 6;
                let errorAlreadyShown = {shown: false, retries: 0};
                try {
                    errorAlreadyShown = JSON.parse(sessionStorage.getItem(sessionBadGateway)) || {shown: false, retries: 0};
                } catch (e) {}

                errorAlreadyShown.retries++;
                if (errorAlreadyShown.retries > maxRetries) {
                    swal({
                        type: 'warning',
                        title: '¡Ups!',
                        html: 'No hemos podido contactar con el servidor. El equipo técnico ya fue notificado',
                        timer: 5000
                    }).then(() => {
                        sessionStorage.removeItem(sessionBadGateway);
                        window.location.href = '/maintenance/offline-ui';
                    });
                    return;
                }
                if (!errorAlreadyShown.shown) {
                    errorAlreadyShown.shown = true;
                    sessionStorage.setItem(sessionBadGateway, JSON.stringify(errorAlreadyShown));

                    swal({
                        type: 'warning',
                        title: '¡Ups!',
                        html: 'Estamos teniendo dificultades. <br> En 30 segundos lo volveremos a intentar',
                        timer: 30000
                    }).then((reason) => {
                        if (reason.dismiss === swal.DismissReason.timer) {
                            errorAlreadyShown.shown = false;
                            sessionStorage.setItem(sessionBadGateway, JSON.stringify(errorAlreadyShown));
                            window.location.reload();
                            return;
                        }
                        setTimeout(() => {
                            errorAlreadyShown.shown = false;
                            sessionStorage.setItem(sessionBadGateway, JSON.stringify(errorAlreadyShown));
                            window.location.reload();
                        }, 30000);
                    });
                }
            }
        }
    }
}

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MvtaInterceptor, multi: true }
    ]
})
export class InterceptorModule { }
