import { Injectable, OnDestroy } from '@angular/core';
import { AccountService, AuthService } from '../auth';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state?: string;
  target?: boolean;
  name: string;
  type?: string;
  badge?: BadgeItem[];
  path?: string;
  children?: ChildrenItems[];

  hiddenIfFeature?: string;
  visibleForFeature?: string;
  visibleForSchemas?: string[];
  role?: string;
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];

  hiddenIfFeature?: string;
  visibleForFeature?: string;
  role?: string;
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS: Array<Menu> = [
  {
    label: 'Panel de Navegación',
    main: [
      {
        state: 'inicio',
        name: 'Inicio',
        type: 'link',
        icon: 'ti-home',
      },
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-dashboard',
      },
      {
        state: 'logistica',
        name: 'Mis Pedidos',
        type: 'sub',
        icon: 'ti-truck',
        children: [
          {
            state: 'guide',
            name: 'Guía de logística en Ecommerce',
          },
          {
            state: 'pedidos',
            name: 'Pedidos',
          },
          {
            state: 'pedidos-tabla',
            name: 'Pedidos (tabla)'
          },
          {
            state: 'guias',
            name: 'Gestión de Guías',
          },
          {
            path: '/logistica/guia/nueva',
            name: 'Cotizar Guías',
            type: 'path',
          },
          // {
          //   state: 'metodos',
          //   name: 'Métodos de Envío',
          //   visibleForFeature: 'logistics_geocode',
          // },
          // {
          //   state: 'zonas-regiones',
          //   name: 'Zonas y Regiones',
          //   visibleForFeature: 'logistics_geocode',
          // },
          // {
          //   state: 'almacenes',
          //   name: 'Almacenes',
          //   visibleForFeature: 'logistics_geocode',
          // },
          // {
          //     state: 'recoger-en-tienda',
          //     name: 'Recoger en tienda'
          // },
          {
            state: 'reglas-envio',
            name: 'Reglas de Envío',
            // hiddenIfFeature: 'logistics_geocode',
          },
        ],
      },
      {
        state: 'carritos',
        name: 'Carritos Abandonados',
        type: 'sub',
        icon: 'ti-shopping-cart-full',
        // feature: 'cart_management',
        children: [
          {
            state: 'lista',
            name: 'Listado',
          },
          {
            state: 'configuracion',
            name: 'Configuración',
          },
          {
            state: 'funnel',
            name: 'KPI\'s funnel de la venta',
          }
        ],
      },
      {
        state: 'clientes',
        name: 'Clientes',
        type: 'link',
        icon: 'ti-id-badge',
      },
      {
        state: 'lealtad',
        name: 'Lealtad',
        type: 'sub',
        icon: 'ti-star',
        children: [
          {
            state: 'cupones',
            name: 'Cupones',
          },
          {
            state: 'recomendaciones',
            name: 'Recomendaciones',
          },
          {
            state: 'descuentos-especiales',
            name: 'Descuentos Especiales',
          },
        ],
      },
      // {
      //     state: 'cobros',
      //     name: 'Cobros',
      //     type: 'sub',
      //     icon: 'ti-credit-card',
      //     children: [
      //         {
      //             state: '',
      //             name: 'Todos'
      //         },
      //         {
      //             state: 'nuevo',
      //             name: 'Nuevo'
      //         }
      //     ]
      // },
      {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'ti-receipt',
        children: [
          {
            state: 'ventas-y-costos',
            name: 'Ventas y Costos',
          },
          {
            state: 'estadisticas',
            name: 'Estadísticas de Venta',
          },
          {
            state: 'kpis-operativos',
            name: 'KPIs Operativos',
          },
          {
            state: 'ventas-sku',
            name: 'Ventas por SKU',
          },
          // {
          //   state: 'funnel',
          //   name: 'Funnel de compra',
          // },
        ],
      },
      {
        state: 'productos',
        name: 'Productos',
        type: 'sub',
        icon: 'ti-bag',
        children: [
          {
            state: '',
            name: 'Todos',
          },
          {
            state: 'nuevo',
            name: 'Agregar',
          },
          {
            state: 'inventario',
            name: 'Inventario',
          },
          {
            state: 'enlaces',
            name: 'Enlaces de Venta',
          },
          {
            state: 'bundles',
            name: 'Bundles',
          },
          {
            state: 'carga-masiva',
            name: 'Carga Masiva',
            badge: [
              {
                type: 'danger',
                value: 'Beta',
              },
            ],
          },
          {
            state: 'campos-personalizados',
            name: 'Campos Personalizados',
          },
          {
            state: 'politica-precios-pedidos',
            name: 'Política de precios en pedidos',
            visibleForSchemas: ['chia_mia', 'chia_mia_sandbox'],
          },
        ],
      },
      {
        state: 'eventos',
        name: 'Eventos',
        type: 'link',
        icon: 'ti-sharethis',
      },
      {
        state: 'integraciones',
        name: 'Integraciones',
        type: 'link',
        icon: 'ti-sharethis',
      },
      {
        state: 'widget',
        name: 'Configurar Carrito',
        type: 'link',
        icon: 'ti-shopping-cart',
        hiddenIfFeature: 'transaction_fee',
      },
      {
        state: 'content-manager',
        name: 'Gestión de Contenidos',
        type: 'link',
        icon: 'ti-layout',
        visibleForFeature: 'content_manager'
      },
      {
        state: 'sitebuilder',
        name: 'Mi Website',
        type: 'sub',
        icon: 'ti-world',
        visibleForFeature: 'website',
        children: [
          // {
          //   state: 'plantillas-adicionales',
          //   name: 'Marketplace de Plantillas Predefinidas',
          // },
          // {
          //   path: '/template-editor',
          //   name: 'Editor de Plantilla',
          //   type: 'path',
          // },
          // {
          //   state: 'plantillas',
          //   name: 'Diseñador de Sitio Web',
          //   badge: [
          //     {
          //       type: 'danger',
          //       value: 'Beta',
          //     },
          //   ],
          // },
          {
            path: '/website-builder',
            name: 'Diseñador',
            type: 'path',
          },
          // {
          //   state: 'seo',
          //   name: 'SEO',
          // },
          {
            state: 'domain',
            name: 'Dominio',
          },
          /* {
             state: 'template-editor',
             name: 'Edición de plantillas'
           } */
        ],
      },
      {
        state: 'cuenta',
        name: 'Mi Cuenta',
        type: 'sub',
        icon: 'ti-settings',
        children: [
          {
            state: 'informacion',
            name: 'Información',
          },
          {
            state: 'terminos',
            name: 'Declaraciones Legales',
          },
          {
            state: 'cobros',
            name: 'Procesadores de Pagos',
          },
          {
            state: 'accesos',
            name: 'Accesos y Notificaciones',
          },
          {
            state: 'planes-pagos',
            name: 'Suscripciones y Pagos',
          },
          {
            state: 'emails-transaccionales',
            name: 'Correos transaccionales',
          },
        ],
      },
    ],
  },
];

@Injectable()
export class MenuItems implements OnDestroy {
  tokenChangeObserver;
  roles: Array<String>;
  features: Array<String>;
  restrictions: Array<String>;
  panelRestricted = false;
  schema = '';

  constructor(
    private authService: AuthService,
    private accountService: AccountService
  ) {
    const sRoles = this.authService.tokenDetails().roles || '';
    this.roles = (sRoles.split(',') || []).map((r) =>
      r.replace('ROLE_', '').trim().toUpperCase()
    );
    this.features = [];
    this.tokenChangeObserver = this.accountService.tokenChange.subscribe(() =>
      this.setFeatures()
    );
    this.setFeatures();
  }

  ngOnDestroy() {
    this.tokenChangeObserver.unsubscribe();
  }

  setFeatures() {
    if (!this.accountService.getToken()) {
      this.features = [];
      return;
    }
    const tokenDetails = this.accountService.tokenDetails();
    const sFeats = tokenDetails.scope || '';
    this.schema = tokenDetails.aud || '';

    this.features = (sFeats.split(' ') || [])
      .map((f) => f.trim())
      .filter((f) => typeof f === 'string' && f.length > 0)
      .map((f) => f.split(':')[0]);

    const sRestrictions = this.accountService.tokenDetails().restrictions || '';
    this.restrictions = (sRestrictions.split(' ') || [])
      .map((f) => f.trim())
      .filter((f) => typeof f === 'string' && f.length > 0);
    this.panelRestricted = this.restrictions.some((r) => r === 'panel');
  }

  getAll(): Menu[] {
    return MENUITEMS.map((mi) => {
      mi.main = mi.main
        .filter((i) => !this.panelRestricted || i.state === 'cuenta')
        .filter(
          (i) => !i.role || this.roles.indexOf(i.role.trim().toUpperCase()) > -1
        )
        .filter(
          (i) =>
            !i.hiddenIfFeature ||
            this.features.indexOf(i.hiddenIfFeature.trim()) === -1
        )
        .filter(
          (i) =>
            !i.visibleForFeature ||
            this.features.indexOf(i.visibleForFeature.trim()) > -1
        )
        .map((m) => {
          if (m.children !== undefined && Array.isArray(m.children)) {
            m.children = m.children
              .filter(
                (c) =>
                  !c.hiddenIfFeature ||
                  this.features.indexOf(c.hiddenIfFeature.trim()) === -1
              )
              .filter(
                (c) =>
                  !c.visibleForFeature ||
                  this.features.indexOf(c.visibleForFeature.trim()) > -1
              )
              .filter(
                (i) =>
                  !i['visibleForSchemas'] ||
                  i['visibleForSchemas'].indexOf(this.schema) > -1
              );
          }
          return m;
        });
      return mi;
    });
  }
}
