import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce } from '../../shared/elements/debounce';
import { ThemeEditorSectionConfigService } from './sections.service';

@Component({
    selector: 'mvta-theme-editor-footer',
    templateUrl: 'footer.component.html'
})
export class ThemeEditorFooterConfigComponent implements OnInit {
    sectionValue;
    debouncedSectionChange;
    availablePages = [];
    editingItem = null;
    editingIdx = -1;
    editingMenu = 'left';

    @Input()
    get section() {
        return this.sectionValue;
    }

    set section(val) {
        if (val === null || val === undefined) {
            this.sectionValue = this.defaultValues();
            return;
        }
        this.sectionValue = val;
    }

    @Output()
    goBack = new EventEmitter();

    @Output()
    sectionChange = new EventEmitter();

    constructor(private sectionsService: ThemeEditorSectionConfigService) {
    }

    ngOnInit() {
        this.debouncedSectionChange = debounce(() => {
            this.sectionChange.emit(this.sectionValue);
        }, 800);
        this.availablePages = this.sectionsService.getAvailablePages();
    }

    onSectionChange() {
        this.debouncedSectionChange();
    }

    onHideSocialToggle() {
        this.sectionValue.hidePaymentMethods = !this.sectionValue.hidePaymentMethods;
        this.onSectionChange();
    }

    onEditMenuItem(item, idx, menu, ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.editingItem = Object.assign({}, item);
        this.editingIdx = idx;
        this.editingMenu = menu;
    }

    onCancelEditItem() {
        this.editingItem = null;
        this.editingIdx = -1;
    }

    onUpdateItem() {
        if (this.editingIdx === -1) {
            this.onCancelEditItem();
            return;
        }
        if (this.editingMenu === 'left') {
            this.sectionValue.leftMenu[this.editingIdx] = this.editingItem;
        } else {
            this.sectionValue.rightMenu[this.editingIdx] = this.editingItem;
        }

        this.onSectionChange();
        this.onCancelEditItem();
    }

    onAddItem(menu) {
        const newItem = {
            title: 'Nueva Sección',
            uri: ''
        };
        let lastIdx = -1;
        if (menu === 'left') {
            this.sectionValue.leftMenu.push(newItem);
            lastIdx = this.sectionValue.leftMenu.length - 1;
        } else {
            this.sectionValue.rightMenu.push(newItem);
            lastIdx = this.sectionValue.rightMenu.length - 1;
        }

        this.onEditMenuItem(newItem, lastIdx, menu, null);
    }

    onGoBack(ev) {
        this.goBack.emit(ev);
    }

    defaultValues() {
        return {
            showPaymentMethods: false
        };
    }
}
