import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitArray'
})

export class SplitArrayPipe implements PipeTransform {
  transform(array: any[], sliceSize: number): any[] {
    const chunks = [];

    const j = array.length;
    for (let i = 0; i < j; i += sliceSize) {
      chunks.push(array.slice(i, i + sliceSize));
    }

    return chunks;
  }
}
