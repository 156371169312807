import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    private jwtHelper: JwtHelperService;
    public redirectUrl = '';

    constructor(private router: Router) {
        this.jwtHelper = new JwtHelperService();
    }

    save(token) {
        sessionStorage.setItem(environment.sessionToken, token);
    }

    logout() {
        sessionStorage.removeItem(environment.sessionToken);
        this.router.navigate(['/auth/login']);
    }

    redirect() {
        if (this.redirectUrl && this.redirectUrl.length) {
            this.router.navigateByUrl(this.redirectUrl);
            this.redirectUrl = '';
        }
    }

    isLoggedIn() {
        const token = this.getToken();
        return (token.length > 10 && !this.jwtHelper.isTokenExpired(token));
    }

    tokenDetails() {
        return this.isLoggedIn() ? this.jwtHelper.decodeToken(this.getToken()) : {};
    }

    tokenInHeader() {
        return 'Bearer ' + this.getToken();
    }

    getToken() {
        return sessionStorage.getItem(environment.sessionToken) || '';
    }
}
